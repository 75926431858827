import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Toast } from '@react/react-spectrum/Toast'
import Chat from '@spectrum-icons/workflow/Chat'
import { Image } from '@adobe/react-spectrum'
import WarnIcon from '@spectrum-icons/workflow/Alert'
import moment from 'moment'
import assigned from '../../Theme/Styles/Icons/conversation-assigned.svg'
import { setCurrentConversation } from '../../redux/actions/contactCenterActions'
import { triggerNavBar } from '../../redux/actions/navBarActions'
import { sendChatEvents } from '../../helpers/analytics-helpers'
import localization from './lang/localization'
import './Notification.scss'
import Close from '@spectrum-icons/workflow/Close'
import '../../helpers/string-extentions'

const TOASTS = ['error', 'connection-gain', 'connection-lost'];

interface IProps {
  notification: { show: boolean; type: string; data: any; newConv: any }
  currentConversation: any
  language: string
}

const Notification = (props: IProps) => {
  const { notification, currentConversation, language } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [content, setContent] = React.useState<any>({})
  const lang = language || 'en'
  const alertdata: any = {
    warning: {
      icon: <WarnIcon />,
      title: 'Warning',
      subTitle: ''
    },
    new: {
      icon: <Chat />,
      title: localization[lang].NEW_NOTIFICATION_TITLE,
      subTitle: ''
    },
    transfer: {
      icon: <Image width='18px' height='18px' src={assigned} alt='transfer' />,
      title: ''
    },
    closed: {
      icon: <Chat />,
      title: localization[lang].CONVERSATION_CLOSED,
      subTitle: `${moment().format('DD MMM YYYY')} | ${moment().format('LT')}`
    },
    closedCustomer: {
      icon: <Chat />,
      title: localization[lang].TOASTS.CHAT_CLOSED_BY_CUSTOMER.replace('[Conversation ID]', notification?.data),
    }
  }

  React.useEffect(() => {
    const tempContent = {
      ...alertdata[notification.type]
    }
    if (notification.type === 'new') {
      const toastMsgBody = notification.data;

      tempContent.subTitle = (typeof toastMsgBody === 'string' && toastMsgBody !== '') ? toastMsgBody.truncate(64, '.....') : '';
    } else if (notification.type === 'transfer') {
      tempContent.title = `${localization[lang].TRANSFER_NOTIFICATION_TITLE} ${notification?.data?.queue?.name} ${localization[lang][notification.data?.option.toUpperCase()]}`
    } else if (notification.type === 'closed') {
      tempContent.title = notification.data;
      tempContent.subTitle = `${moment().format('DD MMM YYYY')} | ${moment().format('LT')}`;
    } else if (notification.type === 'closedCustomer') {
      tempContent.title = localization[lang].TOASTS.CHAT_CLOSED_BY_CUSTOMER.replace('[Conversation ID]', notification?.data);
    }
    setContent(tempContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversation, notification.type])

  React.useEffect(() => {
    const initialContactId = notification?.newConv?.ocPlatformData?.chatInfo?.initialContactId || (window as any).initialContactId
    const status = notification?.newConv?.ocPlatformData?.chatInfo?.status || notification.type

    if (typeof initialContactId === 'string' && initialContactId !== '') {
      ; (window as any).initialContactId = initialContactId
    }

    (notification.type !== 'error') && setTimeout(() => {
      dispatch({ type: 'SETNOTIFICATION', payload: { show: false, type: '' } })
      sendChatEvents({
        initialConvId: initialContactId,
        value: status,
        type: 'render',
        subType: 'chat-notification'
      })
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.type])

  const openNewChat = () => {
    if (location.pathname === '/history') dispatch(triggerNavBar({ chatToggle: false, historyToggle: true }, { chat: true }))
    history.push('/chat')
    if (notification.type === 'new') dispatch(setCurrentConversation(notification.newConv))
    else if (notification.type === 'closed' || notification.type === 'transfer') dispatch(setCurrentConversation(currentConversation))
  }

  const closeToast = () => {
    dispatch({ type: 'SETNOTIFICATION', payload: { show: false, type: '' } })
  }

  if (TOASTS.includes(notification.type)) {
    return (
      <Toast width='auto' className={`remove-participant remove-participant-nw-mode ${notification.type}-background remove-participant-icon`}>
        <div className='notification-content' >
          <div className='remove-participant-text'>
            <span className='remove-participant-title'>{notification?.data}</span>
            <button onClick={closeToast} className='remove-participant-close'><Close UNSAFE_className='remove-participant-icon' size='S' /></button>
          </div>
        </div >

      </Toast >
    )
  } else {
    return (
      <>
        <Toast className={`notification ${`${notification.type}-background`}`} onClick={openNewChat}>
          <div className='notification-content'>
            {alertdata[notification?.type]?.icon && <div className='notification-icon'>{alertdata[notification.type].icon}</div>}
            {notification.type !== 'transfer' && (
              <div className='notification-text'>
                <span className='notification-title'>{content.title}</span>
                <span className='notification-subtitle'>{notification && notification?.type === 'warning' && notification?.data ? notification?.data : content.subTitle}</span>
              </div>
            )}
            {notification.type === 'transfer' && (
              <div className='notification-text'>
                <span className='notification-title'>{content.title}</span>
              </div>
            )}
          </div>
        </Toast>
      </>
    )
  }

}

export default Notification
