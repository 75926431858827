import ContactCenter from '../../Apps/ContactCenter/Modules/ContactCenter'
import { updateCTRForAdobeAnswersAnalytics } from '../../helpers/ctr-updates';
import { enableRUByQueue, enableMockOfferId, enableCaseNotesByQueue } from '../../utils/fg-utils';

export const checkAdobeAnswerEligibility = (queue: any) => (dispatch: any) => {
  let fastFollowsAdobeAnswers = false
  try {
    fastFollowsAdobeAnswers = JSON.parse(localStorage.getItem('fastFollowFlags'))?.FAST_FOLLOWS_GENAI_ADOBE_ANSWERS === true
  } catch (e) {
    console.log('Error: Parsing localStorage for FAST_FOLLOWS_GENAI_ADOBE_ANSWERS', e)
  }

  if (fastFollowsAdobeAnswers) {
    ContactCenter.getAdobeAnswersSkills()
      .then(async (skills) => {
        const skillsLowerCase = skills.map((skill) => skill.toLowerCase())
        const shouldShowAA = skillsLowerCase.includes(queue.toLowerCase())
        dispatch({ type: 'SET_ADOBE_ANSWERS_ELIGIBLE', payload: shouldShowAA })
        await updateCTRForAdobeAnswersAnalytics(shouldShowAA)
        console.log('SET_ADOBE_ANSWERS_ELIGIBLE:  ', shouldShowAA)
      })
      .catch((err) => {
        console.log('Error fetching Adobe Answers skills from Floodgate: ', err)
      })
  }
}
export const checkRUEligibility = (queue: string, agentLDAP: string) => async (dispatch: any) => {
  let fastFollowsRUWidget = false
  try {
    fastFollowsRUWidget = JSON.parse(localStorage.getItem('fastFollowFlags'))?.FAST_FOLLOWS_RECOMMENDED_UPGRADE === true
  } catch (e) {
    console.log('Error: Parsing localStorage for FAST_FOLLOWS_RECOMMENDED_UPGRADE', e)
    fastFollowsRUWidget = false
  }
  if (fastFollowsRUWidget) {
    try {
      fastFollowsRUWidget = await enableRUByQueue(queue, agentLDAP) 
      dispatch({ type: 'SET_RU_ELIGIBLE', payload: fastFollowsRUWidget })
    } catch (e) {
      console.log('Error fetching RU Eligiblity from Floodgate: ', e)
      dispatch({ type: 'SET_RU_ELIGIBLE', payload: false })
    }
    
  }
}

export const checkMockOfferIdEligibility = () => async (dispatch: any) => {
  const fastFollowsMockOfferId = await enableMockOfferId();
  dispatch({ type: 'SET_ENABLE_MOCK_OFFER_ID', payload: fastFollowsMockOfferId })
}

export const checkCaseNotesEligibility = 
  (queue: string, caseNotesNotAllowed: boolean, ldap: string, currentContactId: string) => 
  async (dispatch: any) => {
    try {
      console.log(`Checking caseNotesEligibility for conversation with currentContactId: ${currentContactId}, queue: ${queue}`);
      
      if (!queue) {
        console.log(`dispatch caseNotesEligibility for currentContactId: ${currentContactId} - ${false}`);
        dispatch({ type: 'SET_CASE_NOTES_ELIGIBLE', payload: false });
        return;
      }

      const caseNotesEligibility = await enableCaseNotesByQueue(queue, ldap);

      console.log(`dispatch caseNotesEligibility for currentContactId: ${currentContactId} caseNotesEligibility: ${caseNotesNotAllowed ? false: caseNotesEligibility}`);
      
      dispatch({ type: 'SET_CASE_NOTES_ELIGIBLE', payload: caseNotesNotAllowed ? false: caseNotesEligibility });
    } catch (e) {
      console.error('Error fetching Case Notes Eligibility from Floodgate:', e);
      dispatch({ type: 'SET_CASE_NOTES_ELIGIBLE', payload: false });
    }
};