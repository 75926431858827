/*eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'amazon-connect-streams'
import 'amazon-connect-chatjs'
import {
  AGENT_DEFAULT_STATUS,
  AGENT_OFFLINE_STATUS,
  AGENT_MISSED_STATUS,
  CONVERSATION_ACTIVE_STATUS,
  CONVERSATION_IDLE_STATUS,
  CONVERSATION_OVERDUE_STATUS,
  CONVERSATION_CLOSED_STATUS,
  CONVERSATION_ON_HOLD_TIME_ONE_MIN,
  CONVERSATION_ON_HOLD_TIME_THREE_MIN,
  CALLEVENTS,
  // ENABLE_SUMMARY,
  FEATURE_FLAG_AA_Q,
  DEFAULT_POLLING_FREQUENCY,
  DEFAULT_TRIGGER_THRESHOLD_RTS
} from '../../../Apps/ContactCenter/Constants'
import store from '../../../redux/store'

import {
  initCCP,
  setConversations,
  addMessage,
  setCurrentConversation,
  setCurrentStatus,
  setAgentDetails,
  setACData,
  endCall,
  IncomingCall,
  setAutoTranslation,
  setOutBoundCall,
  setAcw,
  setBannerInfo,
  setAuthFail,
  setMessageMetaData,
  setConversationSummary,
  setConversationSentiments,
  setContactRealTimeSentiment,
  setIsBarged
} from '../../../redux/actions/contactCenterActions'
import { setPreferences, setRingerDeviceDetails } from '../../../redux/actions/preferencesActions'
import audioFile from '../../../assets/alert.wav'
import messageAudioFile from '../../../assets/newmessagealert.wav'
import { ContactRecord, initConversationACData, messageMetaDataState } from '../Models'
import { getConversationHistory, updateContactAttributes, outboundMetadata, getConversationId, getFeatureFlags, getFlagsForConversationSummary, sendCaseNotes } from '../Api/api'
import { fetchOngoingConversations, fetchCsatForAgent } from '../Api/ongoingConversation'
import { fetchContactLegSentiments, fetchContactRealTimeSentiments, fetchConversationSummary, fetchTranscripts, fetchTranslationTranscripts } from '../Api/transcripts'
import env_variables from '../../../config/env_variables'
import localization from '../../../Layout/Notification/lang/localization'
import { get } from 'lodash'
import logo from '../../../Theme/Styles/Icons/adobe.png'
import Utility from './Utility'
import { agentLanguages } from './Languages'
import { updateCTRForConversationSummaryAnalytics } from '../../../helpers/ctr-updates'
import { compareAdobeAnswerAndCCPMessage, getCopiedAdobeAnswer } from '../../../utils/analytics-utils'
import {
  // enableConvSummaryByQueue,
  queryRealTimeSentimentFloodgate
} from '../../../utils/fg-utils'
import { delayOrInvokeInterval } from '../../../utils/utils'

declare const window: any

let agentObject: any
let onTypingCustomer: any = {}
let transferChats: any = {}
const closeConvsId: any = {}
export default class ContactCenter {
  static audioObj = new Audio(audioFile)

  static audioObjNewMsg = new Audio(messageAudioFile)

  constructor(profileInfo: any) {
    store.dispatch(setPreferences(profileInfo.email))
    try {
      const that = this
      const { CCP_URL } = env_variables
      const LOGIN_URL = env_variables.CCP_LOGIN_URL
      const { AWS_REGION } = env_variables
      var containerDiv = document.getElementById('ccpContainer')

      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      // iframe.onload = function () {
      //   if (iframe.parentNode) {
      //     iframe.parentNode.removeChild(iframe)
      //   }
      // }
      iframe.src = LOGIN_URL
      document.body.appendChild(iframe)

      connect.core.initCCP(containerDiv, {
        ccpUrl: CCP_URL,
        // loginUrl: LOGIN_URL,
        region: AWS_REGION,
        loginPopup: false, // optional, defaults to `true`
        loginPopupAutoClose: true, // optional, defaults to `false`
        softphone: {
          // optional, defaults below apply if not provided
          allowFramedSoftphone: true,
          disableRingtone: false // optional, defaults to false
        },
        chat: {
          disableRingtone: true
        },
        loginOptions: {
          // optional, if provided opens login in new window
          autoClose: true, // optional, defaults to `false`
          height: 1, // optional, defaults to 578
          width: 1, // optional, defaults to 433
          top: 0, // optional, defaults to 0
          left: 0 // optional, defaults to 0
        }
      })

      connect.core.onAuthFail((cb: connect.SuccessFailCallback<[]>) => {
        console.log('Auth fail onAuthFail', cb)
        store.dispatch(setAuthFail(true))
        store.dispatch(initCCP())
      })

      connect.core.onAccessDenied((cb: connect.SuccessFailCallback<[]>) => {
        console.log('Auth fail onAccessDenied', cb)
        store.dispatch(setAuthFail(true))
        store.dispatch(initCCP())
      })

      connect.core.onAuthorizeRetriesExhausted(() => {
        console.log('Auth fail onAuthorizeRetriesExhausted')
        store.dispatch(setAuthFail(true))
        store.dispatch(initCCP())
      })

      connect.core.onIframeRetriesExhausted(() => {
        console.log('Auth fail onIframeRetriesExhausted')
        store.dispatch(setAuthFail(true))
        store.dispatch(initCCP())
      })

      connect.contact((contact) => {
        const c = contact
        if (c.getType === connect.ContactType.CHAT) {
          if (
            c.getStatus().type === connect.ContactStateType.ERROR ||
            c.getState().type === connect.ContactStateType.ENDED ||
            c.getState().type === connect.ContactStateType.MISSED ||
            c.getState().type === connect.ContactStateType.REJECTED
          ) {
            c.clear({
              success() {},
              failure(err) {
                console.log('contact clear failed')
              }
            })
            return
          }
          if (c.getState().type === connect.ContactStateType.MISSED || c.getState().type === connect.ContactStateType.ENDED) {
            ContactCenter.setStatus(AGENT_DEFAULT_STATUS)
          }
        }
        if (contact.getType() === connect.ContactType.VOICE || contact.getType() === connect.ContactType.QUEUE_CALLBACK) {
          const { language } = store.getState().preferences
          contact.onConnecting((c) => {
            const AUTO_ACCEPT_CALL = ContactCenter.getFastFollowFlags()?.AUTO_ACCEPT_CALL
            if (!AUTO_ACCEPT_CALL) {
              const { incomingCallData } = store.getState().contactCenter
              const contact = agentObject?.getContacts(connect.ContactType.VOICE) || []
              const attributes = contact[0]?.getAttributes() || {}
              console.log('Incoming attributes ==>', attributes)
              const {
                firstName: { value: incomingFirstName = 'Visitor' } = {},
                lastName: { value: incomingLastName = '' } = {},
                origin: { value: incomingOrigin = '' } = {},
                queuePath: { value: incomingQueuePath = '' } = {},
                botIntent: { value: incomingBotIntent = '' } = {},
                botSubIntent: { value: incomingBotSubIntent = '' } = {},
                country: { value: incomingCountry = '' } = {},
                conversationId: { value: conversationId = '' } = {},
                disconnectedFromAgent: { value: disconnectedFromAgent = '' } = {},
                transferType: { value: incomingTransferType = '' } = {}
              } = attributes

              const transfer = {
                warm: localization[language].TELEPHONY.INCOMING_WARM_TRANSFER,
                cold: localization[language].TELEPHONY.INCOMING_COLD_TRANSFER
              }
              let title = incomingTransferType ? transfer[incomingTransferType] : localization[language].TELEPHONY.INCOMING_CALL
              title = disconnectedFromAgent === 'true' ? localization[language].TELEPHONY.PREVIOUSLY_DISCONNECTED_CUSTOMER : title
              const message = {
                title: title,
                subtitle: localization[language].TELEPHONY.INCOMING_CALL_SUBTITLE,
                type: 'VOICE',
                btnValue: localization[language].TELEPHONY.ACCEPT,
                content: {
                  customerName: {
                    name: localization[language].TELEPHONY.CUSTOMER_NAME,
                    value: `${incomingFirstName} ${incomingLastName}`
                  },
                  origin: {
                    name: localization[language].TELEPHONY.ORIGIN,
                    value: incomingOrigin
                  },
                  queueJourney: {
                    name: localization[language].TELEPHONY.QUEUE_JOURNEY,
                    value: incomingQueuePath
                  },
                  intent: {
                    name: localization[language].TELEPHONY.INTENT,
                    value: incomingBotIntent
                  },
                  subIntent: {
                    name: localization[language].TELEPHONY.SUB_INTENT,
                    value: incomingBotSubIntent
                  },
                  country: {
                    name: localization[language].TELEPHONY.COUNTRY,
                    value: incomingCountry
                  },
                  contactId: {
                    name: localization[language].TELEPHONY.CONTACT_ID,
                    value: contact[0]?.contactId || ''
                  },
                  conversationId: {
                    name: localization[language].TELEPHONY.CONVERSATION_ID,
                    value: conversationId || contact?.[0]?.getInitialContactId()
                  }
                }
              }
              if (incomingCallData.openPopup === '') {
                const missedData = {
                  message: {},
                  isMissed: false
                }
                store.dispatch({ type: 'MISSED_CALL', payload: missedData })
                const data = {
                  message: message,
                  openPopup: 'yes'
                }
                store.dispatch(IncomingCall(data))
              }
            } else {
              ContactCenter.acceptIncomingCall()
            }
          })
          contact.onIncoming((c) => {
            //For queued callback
            const { incomingCallData } = store.getState().contactCenter
            const contact = agentObject?.getContacts(connect.ContactType.QUEUE_CALLBACK) || []
            const attributes = contact[0]?.getAttributes() || {}
            console.log('Incoming attributes ==>', attributes)
            const {
              firstName: { value: incomingFirstName = 'Visitor' } = {},
              lastName: { value: incomingLastName = '' } = {},
              origin: { value: incomingOrigin = '' } = {},
              queuePath: { value: incomingQueuePath = '' } = {},
              botIntent: { value: incomingBotIntent = '' } = {},
              botSubIntent: { value: incomingBotSubIntent = '' } = {},
              country: { value: incomingCountry = '' } = {},
              conversationId: { value: conversationId = '' } = {},
              disconnectedFromAgent: { value: disconnectedFromAgent = '' } = {}
            } = attributes

            const message = {
              title: disconnectedFromAgent === 'true' ? localization[language].TELEPHONY.PREVIOUSLY_DISCONNECTED_CUSTOMER : localization[language].TELEPHONY.INCOMING_CALL,
              subtitle: localization[language].TELEPHONY.INCOMING_CALL_SUBTITLE,
              type: 'QUEUE_CALLBACK',
              btnValue: localization[language].TELEPHONY.ACCEPT,
              content: {
                customerName: {
                  name: localization[language].TELEPHONY.CUSTOMER_NAME,
                  value: `${incomingFirstName} ${incomingLastName}`
                },
                origin: {
                  name: localization[language].TELEPHONY.ORIGIN,
                  value: incomingOrigin
                },
                queueJourney: {
                  name: localization[language].TELEPHONY.QUEUE_JOURNEY,
                  value: incomingQueuePath
                },
                intent: {
                  name: localization[language].TELEPHONY.INTENT,
                  value: incomingBotIntent
                },
                subIntent: {
                  name: localization[language].TELEPHONY.SUB_INTENT,
                  value: incomingBotSubIntent
                },
                country: {
                  name: localization[language].TELEPHONY.COUNTRY,
                  value: incomingCountry
                },
                contactId: {
                  name: localization[language].TELEPHONY.CONTACT_ID,
                  value: contact[0]?.contactId || ''
                },
                conversationId: {
                  name: localization[language].TELEPHONY.CONVERSATION_ID,
                  value: conversationId || contact?.[0]?.getInitialContactId()
                }
              }
            }
            if (incomingCallData.openPopup === '') {
              const data = {
                message: message,
                openPopup: 'yes'
              }
              store.dispatch(IncomingCall(data))
            }
          })
          contact.onMissed((c) => {
            ContactCenter.setStatus(AGENT_MISSED_STATUS)
            const { origin: { value: incomingOrigin = '' } = {} } = c?.getAttributes()
            console.log('Missed call incomingOrigin', c?.getType())
            const contactType = c?.getType()
            const ALLOWED_CALL_ORIGINS = ['outbound-phone', 'chat-to-phone', 'bot-to-phone']
            if (
              incomingOrigin &&
              (!ALLOWED_CALL_ORIGINS.includes(incomingOrigin) ||
                //in case if outbound call was transferred and call was not accepted
                //MissedCall should be poped up
                (ALLOWED_CALL_ORIGINS.includes(incomingOrigin) && c?.getInitialContactId() !== c.contactId && c?.getAttributes()?.queuePath?.value?.length > 1))
            ) {
              ContactCenter.openMissedCallPopUp()
            } else ContactCenter.clearContact()
          })
          contact.onACW((c) => {
            const onACW = 'CONTACT FLOW: on onACW, ACW Timer has started.'
            connect.getLog().info('%s', onACW)
            store.dispatch(IncomingCall({ openPopup: '' }))
            store.dispatch(setAcw(true))
            Utility.onAcwAgentTimer()
          })
          contact.onEnded((c) => {
            const currContactId = c.getContactId()

            window.sendCTRItemToAmazonConnect(currContactId)
            connect.getLog().info('%s', 'CONTACT FLOW: on Ended, Call ended by customer/agent')
            store.dispatch(IncomingCall({ openPopup: '' }))
            store.dispatch({ type: 'RECORDING_DISABLED', payload: { success: false } })
            const voiceConnection = contact.getAgentConnection() as connect.VoiceConnection
            if (voiceConnection) {
              voiceConnection.destroy()
              store.dispatch(setOutBoundCall(false))
            }
          })
          contact.onRefresh((c) => {
            Utility.callInfoHandle(c, 'onRefresh')
          })
          contact.onConnected((c) => {
            const { toolBarCustomSelection, agentDetails, conversations } = store.getState().contactCenter
            const { ldap = '' } = agentDetails
            Utility.callInfoHandle(c, 'onConnected')
            const attributes = c?.getAttributes() || {}
            const {
              conversationId: { value: outboundConvId = '' } = {},
              linkedConversationId: { value: outboundLinkConvId = '' } = {},
              agentId: { value: outboundAgentId = '' } = {},
              customerId: { value: outboundCustomerId = toolBarCustomSelection.customerId } = {},
              origin: { value: outboundOrigin = toolBarCustomSelection.origin } = {},
              queueName: { value: outboundQueue = toolBarCustomSelection.queueName } = {},
              countryCode: { value: outboundCountryCode = toolBarCustomSelection.queueARN } = {},
              conversationStartDate: { value: outboundConversationStartDate = toolBarCustomSelection.callStartTime } = {},
              handoffCount: { value: outboundHandOffCount = '0' } = {}
            } = attributes
            // During warm transfer the redux state is updating in 2 places first onconnected event listner and second acceptIncomingCall block - UpsertVoiceContact().
            //To block that update agent level contacts filtering should be happen if it is not current agent contact then simply return.
            // If transfered call gets routed to the primary agent - Number(outboundHandOffCount) > 0 || isFirstConn
            const isFirstConn = conversations.find((x) => x?.ocPlatformData?.callInfo?.initialContactId === outboundConvId)
            if (!['chat-to-phone', 'outbound-phone'].includes(outboundOrigin)) return
            if (ldap !== outboundAgentId || Number(outboundHandOffCount) > 0 || isFirstConn) return

            console.log('outbound call attributes', attributes)
            const data: ContactRecord = {
              jcAuthData: attributes,
              ocPlatformData: {
                chatInfo: {
                  status: 'Active',
                  initialContactId: outboundConvId,
                  currentContactId: outboundConvId
                },
                callInfo: {
                  ...toolBarCustomSelection,
                  status: 'Active',
                  customerId: outboundCustomerId,
                  callStartTime: outboundConversationStartDate,
                  origin: outboundOrigin,
                  queueName: outboundQueue,
                  countryCode: outboundCountryCode,
                  initialContactId: outboundConvId,
                  currentContactId: outboundConvId,
                  linkedConversationId: outboundLinkConvId,
                  agentId: outboundAgentId,
                  customerId: outboundCustomerId,
                  isMute: false,
                  isHold: false,
                  assignedAgent: true,
                  handoffCount: '0',
                  isJoin: true,
                  isSwap: false,
                  isTransfer: false
                },
                chatTranscript: []
              }
            }
            ContactCenter.UpsertVoiceContact(data)
          })
          if (c.getStatus().type === connect.ContactStateType.ERROR || c.getState().type === connect.ContactStateType.REJECTED) {
            c.clear({
              success() {},
              failure(err) {
                console.log('contact clear failed', err)
              }
            })
            return
          }
          return
        }
        that
          .InitSubscriptions(c)
          .then(() => that.UpsertContact(c))
          .then(() => that.LoadSubscriptions(c))
      })
      connect.core.onInitialized(() => {
        // ContactCenter.onSessionCheck();
        if (iframe.parentNode) {
          iframe.parentNode.removeChild(iframe)
        }
      })

      connect.ChatSession.setGlobalConfig({
        features: {
          messageReceipts: {
            shouldSendMessageReceipts: true,
            throttleTime: 100
          }
        }
      })

      connect.agent((agent) => {
        ContactCenter.getRingerDevicesList()
        const { language } = store.getState().preferences
        const countries = agent.getDialableCountries()
        if (countries) store.dispatch({ type: 'GET_COUNTRIES', payload: countries })
        agentObject = agent
        const agentConfig: connect.AgentConfiguration = agent.getConfiguration()

        ContactCenter.calculateCsat(agentConfig.username)
        // Get all agent states
        const agentStates: string[] = agentConfig.agentStates
          .filter((state) => state.name !== null && state.type === 'not_routable' && state.name !== AGENT_DEFAULT_STATUS)
          .map((agentStates) => agentStates.name)
        let nextStatus = sessionStorage.getItem('agent_nextStatus')
        const previousStatus = sessionStorage.getItem('agent_currentStatus')
        const agentLogOutFlag = localStorage.getItem('agent-logout-flag')

        if (agentLogOutFlag) {
          ContactCenter.setStatus(AGENT_DEFAULT_STATUS)
          localStorage.removeItem('agent-logout-flag')
        }
        if (previousStatus && previousStatus !== 'Offline' && window.performance.navigation.type === 1) {
          nextStatus = nextStatus || ''
          ContactCenter.updateAgentStatus(previousStatus, nextStatus)
        }
        if (agent.getStatus().name === AGENT_OFFLINE_STATUS && previousStatus === 'Init') {
          ContactCenter.setStatus(AGENT_DEFAULT_STATUS)
        }
        if (agent.getStatus().name === AGENT_MISSED_STATUS) {
          const contact = agentObject?.getContacts(connect.ContactType.VOICE) || []
          const attributes = contact[0]?.getAttributes() || {}
          const { origin: { value: incomingOrigin = '' } = {} } = attributes
          if (incomingOrigin) ContactCenter.openMissedCallPopUp()
          else ContactCenter.clearContact()
        }
        ContactCenter.getOngoingConversationForAgent(agentConfig.username)
        // passing empty values for new params as of now
        const routingProfile = agent.getRoutingProfile()
        const { queues: routingQueues = [] } = routingProfile

        const agentDetails = {
          agentName: profileInfo.name,
          routingProfile: agentConfig.routingProfile.name,
          ldap: agentConfig.username,
          email: profileInfo.email,
          states: agentStates.filter((x) => x !== 'Agent PC Recovery'),
          outboundQueues: routingQueues
        }

        store.dispatch(initCCP())
        store.dispatch(setACData(initConversationACData()))
        // Analytics code
        if (window && window.dunamis_analytics) {
          window.dunamis_analytics.sendEvents = true
          window.dunamis_analytics.user = {
            ldap: agentConfig.username,
            email: profileInfo.email
          }
        }

        // fetch quick connects for the agent and set it to AgentDetails
        agent.getEndpoints(agent.getAllQueueARNs(), {
          success(data) {
            const quickConnects = {
              ...agentDetails,
              quickConnects: data.endpoints.filter((quickConnect) => quickConnect.type !== null && quickConnect.type === 'agent'),
              skills: data.endpoints.filter((quickConnect) => quickConnect.type !== null && quickConnect.type === 'queue')
            }

            store.dispatch(setAgentDetails(quickConnects))
          },
          failure(err) {
            store.dispatch(setAgentDetails(agentDetails))
            console.log(`Failed to fetch quick connects for the agent:${agentConfig.name}`, err)
          }
        })
        setInterval(() => {
          // calculate CSAT % for agent
          ContactCenter.calculateCsat(agentConfig.username)
        }, 900000)

        agent.onStateChange((agentStateChange) => {
          const {
            CCPStatus: { updatedBy }
          } = store.getState().contactCenter
          const statusChangeLog = `STATUS_CHANGE : Agent.OnStateChange method got triggered Oldstate : ${agentStateChange.oldState} , new status : ${agentStateChange.newState} and updated by ${updatedBy}`
          console.log(statusChangeLog)
          connect.getLog().info('%s', statusChangeLog)
          if (updatedBy !== 'agent' && agentStateChange.oldState !== 'Init' && agentStateChange.newState === 'Offline') {
            store.dispatch({ type: 'SETOFFLINEINDICATOR', payload: { isOpen: true, type: 'OFFLINE' } })
          }
          if (agentStateChange.newState === 'CallingCustomer') store.dispatch({ type: 'SET_PREV_STATUS', payload: agentStateChange.oldState })
          store.dispatch(setCurrentStatus(agentStateChange.newState, ''))
          if (agentStateChange.oldState === 'AfterCallWork' || (agentStateChange.oldState === 'CallingCustomer' && agentStateChange.newState === 'Available')) {
            ContactCenter.clearContact()
            const data = {
              message: '',
              isMissed: false
            }
            store.dispatch({ type: 'MISSED_CALL', payload: data })
          }
        })
        agent.onEnqueuedNextState((agent) => {
          if (agent.getNextState().name !== AGENT_DEFAULT_STATUS) {
            sessionStorage.setItem('agent_nextStatus', agent.getNextState().name)
          }
          store.dispatch(setCurrentStatus(agent.getNextState().name, 'agent'))
        })
        agent.onSoftphoneError((error) => {
          console.log('onSoftphoneError', error)
          const errorType = `connect-softphone-error-${error?.errorType.replace(/_/g, '-')}-message`
          const data = localization[language].SOFTPHONE_ERROR[errorType] || error?.errorMessage
          store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'error', data } })
        })
        agent.onWebSocketConnectionLost(() => {
          const data = localization[language].TOASTS.CONNECTION_LOST
          store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'connection-lost', data } })
        })
        agent.onWebSocketConnectionGained(() => {
          const data = localization[language].TOASTS.CONNECTION_GAIN
          store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'connection-gain', data } })
        })
      })
      setInterval(() => {
        ContactCenter.updateConversationStatus()
      }, 10000)
      // store.subscribe(() => console.log('State after dispatch: ', store.getState()))
      return
    } catch (error) {
      console.log(error)
    }
  }

  static getRingerDevicesList() {
    connect.core.getFrameMediaDevices().then((devices) => {
      const outputDevices = devices?.filter((d) => d.kind === 'audiooutput') || []
      const ringerDevices = outputDevices.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.label === current.label)) {
          accumulator.push(current)
        }
        return accumulator
      }, [])
      console.log('ringer devices list', JSON.stringify(ringerDevices))
      ContactCenter.setRingerDevicesDetails({ ringerDevices })
    })
  }

  static setRingerDevicesDetails(payload: any) {
    console.log('agentObject', agentObject, typeof agentObject?.setRingerDevice === 'function')
    try {
      if (payload?.ringerDevice && typeof agentObject?.setRingerDevice === 'function') {
        agentObject?.setRingerDevice(payload.ringerDevice)
      }
      const ringer = JSON.parse(localStorage.getItem('ringerDevice'))
      if (ringer) {
        agentObject?.setRingerDevice(ringer?.ringerDevice)
        store.dispatch(setRingerDeviceDetails(ringer))
      }
      store.dispatch(setRingerDeviceDetails(payload))
    } catch (error) {
      console.log(error)
    }
  }

  static connectAgent() {
    connect.agent((agentConnection) => {
      agentObject = agentConnection
    })
  }

  static getFastFollowFlags() {
    try {
      const localData: any = localStorage.getItem('fastFollowFlags')
      return JSON.parse(localData) || {}
    } catch (e) {
      console.log('fastFollowFlags fail', e)
      return {}
    }
  }

  static updateConversationStatus() {
    const {
      contactCenter: { conversations = [] }
    } = store.getState()
    console.log('updateConversationStatus start', JSON.parse(JSON.stringify(conversations)))
    try {
      if (conversations.length !== 0) {
        // let lastConversationTime,currentTime
        // const { conversations } = store.getState().contactCenter
        const cloneConversations = [...conversations]
        cloneConversations.forEach((conversation: any, index: number) => {
          const chatTranscripts = conversation.ocPlatformData.chatTranscript
          let currentStatus = conversation.ocPlatformData.chatInfo.status
          const transferCount = conversation?.jcAuthData?.transferCount?.value
          if (chatTranscripts && chatTranscripts.length !== 0 && conversation.ocPlatformData.chatInfo.status === CONVERSATION_ACTIVE_STATUS) {
            const isNewConversation = ContactCenter.isNewConversation(chatTranscripts, transferCount)
            // Get the latest chat transcript from transcript array
            const transcriptTypeAllow = ['MESSAGE', 'ATTACHMENT']
            const lastMessage = chatTranscripts.filter((transcript: any) => transcriptTypeAllow.includes(transcript.Type))
            const latestChatTranscript = lastMessage[lastMessage.length - 1]
            conversation.ocPlatformData.chatInfo.status = ContactCenter.getCalculatedStatus(latestChatTranscript, isNewConversation, conversation.ocPlatformData.chatInfo.status)
          }
          conversation.ocPlatformData.chatInfo.timer = ContactCenter.updateTimer(
            currentStatus,
            conversation.ocPlatformData.chatInfo.status,
            conversation.ocPlatformData.chatInfo.timer
          )
          cloneConversations[index] = conversation
        })
        store.dispatch(setConversations(cloneConversations))
        console.log('updateConversationStatus end', JSON.parse(JSON.stringify(conversations)))
      }
    } catch (error) {
      console.log(error)
    }
  }

  static isNewConversation(transcripts: any, transferCount: number) {
    // check if transcripts contains messages from agent
    let agentMessage
    if (transferCount > 0) {
      const lastTransferIndex = transcripts.lastIndexOf(
        (transcript: any) => transcript.Type === 'EVENT' && transcript.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined'
      )
      const lastTransferredTranscipt = transcripts.slice(lastTransferIndex, transcripts.length)
      agentMessage = lastTransferredTranscipt.filter((transcript: any) => transcript.Type === 'MESSAGE' && transcript.ParticipantRole === 'AGENT')
    } else {
      agentMessage = transcripts.filter((transcript: any) => transcript.Type === 'MESSAGE' && transcript.ParticipantRole === 'AGENT')
    }
    return agentMessage.length === 0
  }

  static notifications = new Map<string, Notification>()
  static eventListeners = new Map<string, () => void>()

  static browserNotification(conversationId: string, title: string, text: string) {
    let notification: any
    if (document.visibilityState !== 'visible') {
      notification = new Notification(title, { body: text, icon: logo })
      this.notifications.set(conversationId, notification)
      setInterval(() => notification.close(), 5000)
    }
    if (!this.eventListeners.has(conversationId)) {
      const listener = () => {
        if (!document.hidden) {
          const notification = this.notifications.get(conversationId)
          notification && notification.close()
        }
      }
      document.addEventListener('visibilitychange', listener)
      this.eventListeners.set(conversationId, listener)
    }
  }

  static openMissedCallPopUp() {
    try {
      const { language } = store.getState().preferences
      var contact = agentObject?.getContacts(connect.ContactType.VOICE) || []
      if (!contact?.length) {
        contact = agentObject?.getContacts(connect.ContactType.QUEUE_CALLBACK) || []
      }
      const attributes = contact[0]?.getAttributes() || {}
      const phoneNumber = contact[0]?.getInitialConnection()?.getEndpoint()?.phoneNumber || ''
      const {
        firstName: { value: incomingFirstName = 'Visitor' } = {},
        lastName: { value: incomingLastName = '' } = {},
        origin: { value: incomingOrigin = '' } = {},
        queuePath: { value: incomingQueuePath = '' } = {},
        botIntent: { value: incomingBotIntent = '' } = {},
        botSubIntent: { value: incomingBotSubIntent = '' } = {},
        country: { value: incomingCountry = '' } = {},
        conversationId: { value: conversationId = '' } = {}
      } = attributes
      const missedCall = {
        title: localization[language].TELEPHONY.CALL_MISSED,
        subtitle: localization[language].TELEPHONY.CALL_MISSED_SUBTITLE,
        btnValue: localization[language].TELEPHONY.CLOSE_CONTACT,
        content: {
          customerName: {
            name: localization[language].TELEPHONY.CUSTOMER_NAME,
            value: `${incomingFirstName} ${incomingLastName}`
          },
          origin: {
            name: localization[language].TELEPHONY.ORIGIN,
            value: incomingOrigin
          },
          queueJourney: {
            name: localization[language].TELEPHONY.QUEUE_JOURNEY,
            value: incomingQueuePath
          },
          intent: {
            name: localization[language].TELEPHONY.INTENT,
            value: incomingBotIntent
          },
          subIntent: {
            name: localization[language].TELEPHONY.SUB_INTENT,
            value: incomingBotSubIntent
          },
          country: {
            name: localization[language].TELEPHONY.COUNTRY,
            value: incomingCountry
          },
          contactId: {
            name: localization[language].TELEPHONY.CONTACT_ID,
            value: contact[0]?.contactId || ''
          },
          conversationId: {
            name: localization[language].TELEPHONY.CONVERSATION_ID,
            value: conversationId || contact?.[0]?.getInitialContactId()
          },
          customerEndpoint: {
            name: localization[language].TELEPHONY.PHONE_NUMBER,
            value: phoneNumber
          }
        }
      }
      const data = {
        message: missedCall,
        isMissed: true
      }
      store.dispatch({ type: 'MISSED_CALL', payload: data })
    } catch (e) {
      console.log('openMissedCallPopUp Error - ', e)
    }
  }

  async InitSubscriptions(c: connect.Contact) {
    c.onConnecting((c) => {
      try {
        const timerInfo: any = Utility.getActiveTimerInfo()
        const { sound, language } = store.getState().preferences
        const title = localization[language].BROWSER_NOTIFICATIONS.NEW_CONVERSATION_TITLE
        const text = localization[language].BROWSER_NOTIFICATIONS.NEW_CONVERSATION_MESSAGE
        const contactId = c?.contactId || ''

        if (contactId && !timerInfo[contactId]) {
          timerInfo[contactId] = {
            chatAgentStartTime: new Date(),
            voiceAgentStartTime: '',
            voiceAgentEndTime: ''
          }
          Utility.setActiveTimerInfo(timerInfo)
        }
        if (sound) ContactCenter.audioObj.play()
        ContactCenter.browserNotification(contactId, title, text)
      } catch (error) {
        console.log('play notification sound', error)
      }
      try {
        c.accept()
      } catch (error) {
        console.log('accept chat', error)
      }
    })
  }

  static playSound() {
    const { sound } = store.getState().preferences
    sound && ContactCenter.audioObjNewMsg.play()
  }

  static async sendToAgentTypingEvent() {
    connect.agent(async (agent) => {
      const { currentConversation } = store.getState().contactCenter
      const currentContactId = get(currentConversation, 'ocPlatformData.chatInfo.currentContactId', '')
      const contact = agent.getContacts().find((c) => c.contactId === currentContactId)
      if (contact) {
        const cnn = contact.getAgentConnection() as connect.ChatConnection
        if (cnn) {
          const agentChatSession = await cnn.getMediaController()
          await agentChatSession.sendEvent({
            contentType: 'application/vnd.amazonaws.connect.event.typing'
          })
        }
      }
    })
  }

  static getCustomerName = (conversation: any, defaultHeader: string) => {
    const { language } = store.getState().preferences as pState
    if (!conversation?.jcAuthData && defaultHeader) {
      return defaultHeader
    }
    const first = conversation?.jcAuthData?.firstName?.value || ''
    const last = conversation?.jcAuthData?.lastName?.value || ''
    const customerID = conversation?.jcAuthData?.customerID?.value || ''
    if (first && last) {
      return `${first} ${last}`
    } else if (first) {
      return first
    } else if (last) {
      return last
    } else if (customerID && defaultHeader) {
      return defaultHeader
    } else {
      return localization[language].BROWSER_NOTIFICATIONS.VISITOR
    }
  }

  async LoadSubscriptions(c: connect.Contact) {
    const cnn = c.getAgentConnection() as connect.ChatConnection
    if (cnn) {
      const agentChatSession = await cnn.getMediaController()
      agentChatSession.onMessage(async (event: { chatDetails: any; data: any }) => {
        try {
          const { currentView, conversations = [], messageMetaData } = store.getState().contactCenter
          const { language } = store.getState().preferences
          const { data } = event
          const content = data?.Content || ''
          const getCurrentActive = conversations?.find((x: ContactRecord) => x.ocPlatformData.chatInfo.currentContactId === data.ContactId) || {}
          const { ocPlatformData: { chatInfo: { currentContactId: contactId = '' } = {} } = {}, jcAuthData: { language: { value: customerLang = '' } = {} } = {} } =
            getCurrentActive
          const customerName = ContactCenter.getCustomerName(getCurrentActive, '')
          const title = localization[language].BROWSER_NOTIFICATIONS.NEW_MESSAGE_TITLE
          const text = content ? `${customerName}:${content.substring(0, 64)}` : ''
          const { autoTranslation } = store.getState().contactCenter
          const upsertData = autoTranslation[contactId] || {}
          let bargedConnection = false
          bargedConnection = c.isUnderSupervision()
          if (currentView === 'AC_VIEW') {
            store.dispatch({ type: 'SETNEWMESSAGEINDICATOR', payload: true })
          }
          if (bargedConnection) {
            store.dispatch(setIsBarged({ contactId: contactId, isBarged: true }))
          }
          if (!bargedConnection || (data?.ContentType === 'application/vnd.amazonaws.connect.event.participant.left' && data?.ParticipantRole === 'SUPERVISOR')) {
            store.dispatch(setIsBarged({ contactId: contactId, isBarged: false }))
          }
          if (data && data.ParticipantRole === 'AGENT' && (data.Type === 'MESSAGE' || data.Type === 'ATTACHMENT')) {
            let upsertMessageMetaData = messageMetaData[cnn.contactId] || ''
            if (!upsertMessageMetaData) {
              upsertMessageMetaData = messageMetaDataState()
              upsertMessageMetaData.contactId = cnn.contactId
            }
            upsertMessageMetaData.messageId = data.Id
            upsertMessageMetaData.isRead = false
            upsertMessageMetaData.isDelivered = false
            store.dispatch(setMessageMetaData(upsertMessageMetaData))
          }

          if (data && data.ParticipantRole === 'CUSTOMER' && (data.Type === 'MESSAGE' || data.Type === 'ATTACHMENT') && !content.includes('CUSTOM_SYSTEM_MESSAGE_')) {
            ContactCenter.playSound()
            ContactCenter.browserNotification(contactId, title, text)
            if (onTypingCustomer[data.InitialContactId]) {
              store.dispatch({ type: 'SET_ON_TYPING', payload: { isTyping: false, initialContactId: data.InitialContactId } })
              clearInterval(onTypingCustomer[data.InitialContactId].timer)
              onTypingCustomer[data.InitialContactId].timer = null
            }

            if (upsertData.translation) {
              const reqbody = {
                sourceLanguage: customerLang,
                targetLanguage: language,
                texts: [
                  {
                    id: contactId,
                    text: content
                  }
                ]
              }
              const respText = await ContactCenter.getTranslationTranscript(reqbody)
              data.translationText = respText || data?.Attachments[0]?.AttachmentName || ''
            }
          } else if (upsertData.translation) {
            const upsertTrMsg = upsertData.upsertTrMsg || {}
            if (upsertTrMsg[data.Id]) {
              data.translationText = upsertTrMsg[data.Id].Content || ''
            } else if (data.Type === 'ATTACHMENT') {
              data.translationText = data?.Attachments[0]?.AttachmentName || ''
            }
          }

          if (
            (data?.ContentType === 'application/vnd.amazonaws.connect.event.participant.left' || transferChats[data.ContactId]) &&
            (data?.ParticipantRole === 'AGENT' || data?.ParticipantRole === 'CUSTOMER')
          ) {
            transferChats[data.ContactId] = data.ContactId
            data.isChatClosed = true
          }

          store.dispatch(addMessage(data))

          if (data && data.ParticipantRole === 'CUSTOMER' && (data.Type === 'MESSAGE' || data.Type === 'ATTACHMENT') && !content.includes('CUSTOM_SYSTEM_MESSAGE_')) {
            Utility.deliveredEventToCustomer(data, agentChatSession)
          }
        } catch (error) {
          console.log(error)
        }
      })

      agentChatSession.onReadReceipt((cbData: any) => {
        console.log('READ_MESSAGE_DATA', cbData)
        Utility.onReadDeliveredReceipt(cbData, true)
      })

      agentChatSession.onDeliveredReceipt((cbData: any) => {
        console.log('DELIVERED_MESSAGE_DATA', cbData)
        Utility.onReadDeliveredReceipt(cbData, false)
      })

      agentChatSession.onTyping((event: { data: any }) => {
        try {
          const { data } = event
          const { currentConversation } = store.getState().contactCenter
          if (data?.ParticipantRole === 'CUSTOMER') {
            if (currentConversation?.ocPlatformData?.chatInfo?.currentContactId === data.InitialContactId) {
              if (!onTypingCustomer[data.InitialContactId]) {
                onTypingCustomer[data.InitialContactId] = {
                  typingSd: '',
                  timer: null
                }
              }
              ContactCenter.closeIntervalNotActive(data.InitialContactId)
              onTypingCustomer[data.InitialContactId].typingSd = new Date()
              if (!onTypingCustomer[data.InitialContactId].timer) {
                store.dispatch({ type: 'SET_ON_TYPING', payload: { isTyping: true, initialContactId: data.InitialContactId } })
                onTypingCustomer[data.InitialContactId].timer = setInterval(() => {
                  const typingEd = (new Date().getTime() - onTypingCustomer[data.InitialContactId].typingSd.getTime()) / 1000
                  if (typingEd >= 6) {
                    clearInterval(onTypingCustomer[data.InitialContactId].timer)
                    onTypingCustomer[data.InitialContactId].timer = null
                    store.dispatch({ type: 'SET_ON_TYPING', payload: { isTyping: false, initialContactId: data.InitialContactId } })
                  }
                }, 6000)
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      })
    }
    c.onEnded((c) => {
      const currContactId = c.getContactId()

      window.sendCTRItemToAmazonConnect(currContactId)
      ContactCenter.clearConversation(c)
    })
    c.onMissed((c) => {
      ContactCenter.setStatus(AGENT_DEFAULT_STATUS)
      ContactCenter.clearConversation(c)
    })
    c.onACW((c) => {
      ContactCenter.clearConversation(c)
      Utility.onAcwAgentTimer()
    })
    c.onDestroy((c) => {
      console.log('onDestory', c)
      ContactCenter.clearConversation(c)
    })
  }

  static closeIntervalNotActive(activeId: string) {
    if (Object.keys(onTypingCustomer).length > 1) {
      for (const key in onTypingCustomer) {
        if (key !== activeId && onTypingCustomer[key]) {
          clearInterval(onTypingCustomer[key].timer)
          onTypingCustomer[key].timer = null
        }
      }
    }
  }

  async UpsertContact(c: connect.Contact) {
    try {
      const ctr = c.getAttributes()
      const transData: ContactRecord = {
        jcAuthData: { ...ctr },
        ocPlatformData: {
          chatInfo: {
            initialContactId: '',
            currentContactId: '',
            status: 'Active',
            queueName: c.getQueue().name
          },
          chatTranscript: []
        }
      }

      const cnn = c.getAgentConnection() as connect.ChatConnection
      if (cnn) {
        const agentChatSession = await cnn.getMediaController()
        const chatDetails = agentChatSession.getChatDetails() as connect.ChatDetails
        const awsTSdkResponse = await agentChatSession.getTranscript({
          maxResults: 100,
          sortOrder: 'ASCENDING'
        })
        transData.ocPlatformData.chatInfo.initialContactId = chatDetails.initialContactId
        transData.ocPlatformData.chatInfo.currentContactId = chatDetails.contactId
        if (awsTSdkResponse.data) {
          const transcript = awsTSdkResponse.data.Transcript
          let chatPreview = ''
          for (let i = transcript.length - 1; i >= 0; i--) {
            if (
              transcript[i].Type === 'MESSAGE' &&
              transcript[i].ParticipantRole === 'CUSTOMER' &&
              !transcript[i].Content.includes('IGNORE_') &&
              !transcript[i].Content.includes('CUSTOM_SYSTEM_MESSAGE_')
            ) {
              chatPreview = transcript[i].Content
              break
            }
          }

          transData.ocPlatformData.chatTranscript = awsTSdkResponse.data.Transcript
          transData.ocPlatformData.chatInfo.chatStartTime = awsTSdkResponse.data.Transcript[0].AbsoluteTime
          //  const isNewConversation = ContactCenter.isNewConversation(awsTSdkResponse.data.Transcript)
          const lastMessage = awsTSdkResponse.data.Transcript.filter((transcript: any) => transcript.Type === 'MESSAGE')
          const lastAgentMessage = awsTSdkResponse.data.Transcript.filter(
            (transcript: any) =>
              transcript.Type === 'MESSAGE' ||
              (transcript.Type === 'EVENT' && transcript.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined' && transcript.ParticipantRole === 'AGENT')
          )
          const isNewConversation = ContactCenter.isNewConversation(awsTSdkResponse.data.Transcript, transData.jcAuthData.transferCount?.value)
          const currentQueue = transData.jcAuthData.currentQueue?.value
          const currentContactId = transData.ocPlatformData.chatInfo.currentContactId
          const initialContactId = chatDetails.initialContactId

          const { language: agentLanguageCode } = store.getState().preferences
          const agentLanguage = agentLanguages[agentLanguageCode] || 'English'
          const finalSummary = false

          let fastFollowsGenAI = false
          try {
            fastFollowsGenAI = JSON.parse(localStorage.getItem('fastFollowFlags'))?.FAST_FOLLOWS_GENAI_CONV_SUMMARY === true
          } catch (e) {
            console.log('Error: Parsing localStorage for FAST_FOLLOWS_GENAI_CONV_SUMMARY', e)
            fastFollowsGenAI = false
          }
          if (isNewConversation && fastFollowsGenAI) {
            ContactCenter.generateConversationSummary(chatDetails.contactId, awsTSdkResponse.data.Transcript, currentQueue, finalSummary, agentLanguage)
          }

          // check for rts floodgate and fast follows flag
          const { agentDetails: { ldap = '', agentName = '' } = {}, agentFeatureTags } = store.getState().contactCenter
          const featureFlags = await queryRealTimeSentimentFloodgate(ldap)
          const { metadata } = featureFlags
          store.dispatch({ type: 'SET_RTS_ENABLED_QUEUES', payload: metadata?.eligibleQueues ?? [] })
          const isQueueEligible = metadata?.eligibleQueues?.includes(currentQueue) ?? false
          const pollingFrequency = Number(metadata?.pollingFrequency) || DEFAULT_POLLING_FREQUENCY
          store.dispatch({ type: 'SET_RTS_TRIGGER_THRESHOLD', payload: Number(metadata?.alertThreshold) || DEFAULT_TRIGGER_THRESHOLD_RTS })
          const enableRTS = featureFlags?.featureFlags?.includes(currentQueue) ?? false
          store.dispatch({ type: 'SET_ENABLE_RTS', payload: { currentContactId: currentContactId, enabled: enableRTS } })
          const { channel: { value: channel = '' } = {} } = ctr

          // only generate sentiment if queue and ldap is enable for the feature AND the conversation channel is messaging (voice is not enabled yet for RTS)
          if (isNewConversation && (enableRTS || isQueueEligible) && channel === 'messaging') {
            ContactCenter.generateContactLegSentiments(chatDetails.contactId, awsTSdkResponse.data.Transcript, initialContactId)
          }

          if ((enableRTS || isQueueEligible) && channel === 'messaging') {
            ContactCenter.initializeRealTimeSentimentTimer(
              chatDetails.contactId,
              pollingFrequency,
              Number(metadata?.sentimentCharsThreshold),
              ldap,
              agentName,
              currentQueue,
              agentFeatureTags?.ManagerLDAP ?? '',
              initialContactId,
              awsTSdkResponse.data.Transcript,
              enableRTS
            )
          }

          transData.ocPlatformData.chatInfo.status = ContactCenter.getCalculatedStatus(lastMessage[lastMessage.length - 1], isNewConversation, 'Active')
          const localStorageData = localStorage.getItem('activeChatIds')
          if (localStorageData && localStorageData !== null) {
            const activeChatIds = JSON.parse(localStorageData)
            if (
              localStorageData &&
              localStorageData !== null &&
              activeChatIds &&
              activeChatIds !== null &&
              !activeChatIds.includes(transData.ocPlatformData.chatInfo.currentContactId)
            ) {
              transData.ocPlatformData.chatInfo.timer = 60
            } else {
              transData.ocPlatformData.chatInfo.timer = ContactCenter.getCalculatedTimer(lastAgentMessage[lastAgentMessage.length - 1], isNewConversation)
            }
          } else {
            transData.ocPlatformData.chatInfo.timer = 60
          }
          const { conversations } = store.getState().contactCenter
          const cloneConversations = [...conversations]
          if (localStorageData && localStorageData !== null) {
            const activeChatIds = JSON.parse(localStorageData)
            if (activeChatIds && activeChatIds !== null && !activeChatIds.includes(transData.ocPlatformData.chatInfo.currentContactId))
              store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'new', data: chatPreview, newConv: transData } })
          } else {
            store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'new', data: chatPreview, newConv: transData } })
          }
          var foundIndex = cloneConversations.findIndex((x) => x.ocPlatformData.chatInfo.currentContactId === transData.ocPlatformData.chatInfo.currentContactId)
          if (foundIndex < 0) {
            cloneConversations.unshift(transData)
            ContactCenter.getIsNewCustoner(transData, cloneConversations)
          } else cloneConversations[foundIndex] = transData
          store.dispatch(setConversations(cloneConversations))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  static async UpsertVoiceContact(c: connect.contactRecord) {
    const { conversations } = store.getState().contactCenter
    const cloneConversations = [...conversations]
    const {
      ocPlatformData: {
        callInfo: { origin = '' }
      }
    } = c
    let foundIndex = -1
    if (origin === 'chat-to-phone') {
      foundIndex = cloneConversations?.findIndex(
        (x) => x?.ocPlatformData?.chatInfo?.initialContactId === c?.ocPlatformData?.callInfo?.linkedConversationId && x?.ocPlatformData?.chatInfo?.status !== 'Closed'
      )
    }
    if (foundIndex < 0) {
      cloneConversations.unshift(c)
      store.dispatch(setCurrentConversation(c))
    } else {
      cloneConversations[foundIndex].ocPlatformData.callInfo = c.ocPlatformData.callInfo
      cloneConversations[foundIndex].ocPlatformData.chatInfo.status = 'Active'
      console.log('foundIndex', cloneConversations[foundIndex])
      store.dispatch(setCurrentConversation(cloneConversations[foundIndex]))
    }
    store.dispatch(setConversations(cloneConversations))
  }

  static async getIsNewCustoner(object: any, conversations: any) {
    var foundIndex = conversations.findIndex((x: any) => x.ocPlatformData.chatInfo.currentContactId === object.ocPlatformData.chatInfo.currentContactId)
    object.ocPlatformData.chatInfo.newCustomer = await ContactCenter.isNewCustomer(object.jcAuthData?.customerId?.value)
    conversations[foundIndex] = object
    store.dispatch(setConversations(conversations))
  }

  static getCalculatedTimer(latestChatTranscript: any, isNewConversation: any) {
    const lastConversationTime = latestChatTranscript?.AbsoluteTime ? new Date(latestChatTranscript.AbsoluteTime) : new Date()
    const currentTime = new Date()
    // Get the time duration between last conversation and current time
    const duration = isNewConversation
      ? Math.floor(Math.abs((currentTime.getTime() - lastConversationTime.getTime() - 60000) / 1000))
      : Math.floor(Math.abs((currentTime.getTime() - lastConversationTime.getTime() - 180000) / 1000))
    return duration
  }

  static getCalculatedStatus(latestChatTranscript: any, isNewConversation: any, currentStatus: any) {
    const lastConversationTime = latestChatTranscript?.AbsoluteTime ? new Date(latestChatTranscript.AbsoluteTime) : new Date()
    const currentTime = new Date()
    // Get the time duration between last conversation and current time
    const duration = Math.abs(currentTime.getTime() - lastConversationTime.getTime())

    // update conversation status if time duration exceeds 1m for new conversation and 3m for ongoing conversations
    if (duration > (isNewConversation ? CONVERSATION_ON_HOLD_TIME_ONE_MIN : CONVERSATION_ON_HOLD_TIME_THREE_MIN)) {
      switch (latestChatTranscript?.ParticipantRole) {
        case 'CUSTOMER':
        case 'SYSTEM':
          return CONVERSATION_OVERDUE_STATUS
        case 'AGENT':
          return CONVERSATION_IDLE_STATUS
        default:
          return currentStatus
      }
    }
    return currentStatus
  }

  static updateTimer(currentStatus: any, newStatus: any, currentTimer: any) {
    switch (newStatus) {
      case 'Active':
        return currentStatus !== newStatus ? 180 : currentTimer > 0 ? currentTimer - 10 : currentTimer
      case 'Idle':
      case 'Overdue':
        return currentStatus !== newStatus ? 0 : currentTimer + 10
      default:
        return currentTimer
    }
  }

  static setStatus(status: string) {
    sessionStorage.removeItem('agent_nextStatus')
    sessionStorage.removeItem('agent_currentStatus')
    const setStatusLog = `STATUS_CHANGE : OAC Set status method triggered : ${status}`
    console.log(setStatusLog)
    connect.getLog().info('%s', setStatusLog)
    const { language } = store.getState().preferences
    const lang = language || 'en'
    try {
      connect.agent((agent) => {
        const agentNewStatus = agent.getAgentStates().filter((state) => state.name === status)[0]
        agent.setState(
          agentNewStatus,
          {
            success() {
              store.dispatch(setCurrentStatus(agentNewStatus.name, 'agent'))
              connect.getLog().info('%s', `STATUS_CHANGE : OAC static setState method triggered: static setStatus method - success block -  : ${agentNewStatus}`)
            },
            failure(err) {
              console.log(`Failed to set agent status to ${agentNewStatus.name}`, err)
              connect.getLog().info('%s', `STATUS_CHANGE : OAC static setState method triggered: static setStatus method - Failure block -  : ${agentNewStatus}`)
              alert(localization[lang].STATUS_CHANGE_FAILED)
            }
          },
          { enqueueNextState: true }
        )
      })
    } catch (error) {
      console.log(error)
    }
  }

  static updateAgentStatus(status: string, nextStatus: string) {
    try {
      connect.agent((agent) => {
        const states = agent?.getAgentStates() || []
        const agentNewStatus = states.filter((state: { name: string }) => state?.name === status)[0]
        const agentNextStatus = states.filter((state: { name: string }) => state?.name === nextStatus)[0]
        const currentAgentStatusName = agent?.getStatus()?.name || ''
        const currentAgentNextName = agent?.getNextState()?.name || ''
        // if next status is passed
        if (currentAgentStatusName === status && nextStatus !== '') {
          // do not call set status if agent next status is same as what is passed
          if (currentAgentNextName === nextStatus) {
            store.dispatch(setCurrentStatus(currentAgentNextName, 'agent'))
          } else {
            // call set status if agent next status is not same as what is passed. this will update next status
            ContactCenter.setState(agentNextStatus, agent)
          }
        } else if (currentAgentStatusName === status && nextStatus === '') {
          // if next status is not passed and status same as agent current status
          const agentNewStatus = states.filter((state: { name: string }) => state?.name === status)[0]
          // if agent next status is present , call set state with status .. that will cancel next state
          if (currentAgentNextName) {
            ContactCenter.setState(agentNewStatus, agent)
          } else {
            // if agent next status is not present , do not call set state with status . as agent current state is same as status
            store.dispatch(setCurrentStatus(agentNewStatus?.name, 'agent'))
          }
        } else {
          agent.setState(
            agentNewStatus,
            {
              success() {
                store.dispatch(setCurrentStatus(agentNewStatus?.name, 'agent'))
                const previousStatus = sessionStorage.getItem('agent_currentStatus')
                const agentPreviousStatus = states.filter((state: { name: string }) => state?.name === previousStatus)[0]
                const nextStatus = sessionStorage.getItem('agent_nextStatus')
                const agentNextStatus = states.filter((state: { name: string }) => state?.name === nextStatus)[0]
                if (agentPreviousStatus && !agentNextStatus && agentPreviousStatus !== agentNewStatus) {
                  ContactCenter.setState(agentPreviousStatus, agent)
                }
                if (nextStatus && agentNewStatus !== agentNextStatus) {
                  ContactCenter.setState(agentNextStatus, agent)
                }
                connect.getLog().info('%s', `STATUS_CHANGE : OAC static setState method triggered: updateAgentStatus: elseblock - success block -  : ${agentNewStatus}`)
              },
              failure(err: any) {
                console.log(`Failed to set agent status to ${agentNewStatus?.name}`, err)
                connect.getLog().info('%s', `STATUS_CHANGE : OAC static setState method triggered: updateAgentStatus: elseblock - Failure block : ${agentNewStatus}`)
              }
            },
            { enqueueNextState: true }
          )
        }
      })
      //  fetch(env_variables.CCP_URL+"/logout", { credentials: 'include', mode: 'no-cors'})
    } catch (error) {
      console.error(error)
    }
  }

  static setState(status: any, agent: any) {
    agent.setState(
      status,
      {
        success() {
          store.dispatch(setCurrentStatus(status.name, 'agent'))
          connect.getLog().info('%s', `STATUS_CHANGE : OAC static setState method triggered: static setState method - success block : ${status}`)
        },
        failure(err: any) {
          console.log(`Failed to set agent status to ${status.name}`, err)
          connect.getLog().info('%s', `STATUS_CHANGE : OAC static setState method triggered: static setState method - failure block : ${status}`)
        }
      },
      { enqueueNextState: true }
    )
  }

  static async sendMessage(contactID: string, sendData: { message: string; translateMsg?: string }) {
    try {
      connect.agent(async (agent) => {
        const contact = agent.getContacts().find((c) => c.contactId === contactID)
        if (contact) {
          const cnn = contact.getAgentConnection() as connect.ChatConnection
          if (cnn) {
            const agentChatSession = await cnn.getMediaController()
            const { autoTranslation, messageMetaData } = store.getState().contactCenter
            const upsertData = autoTranslation[contactID] || {}
            const ALLOWED_CONTENT_TYPE_JSON = ContactCenter.getFastFollowFlags()?.ALLOWED_CONTENT_TYPE_JSON || false

            const isLargeMessage = (ALLOWED_CONTENT_TYPE_JSON && upsertData.translation) || sendData.message.length > 1024 || false
            const largeMsgPayload = {
              type: 'markdown',
              display: 'show',
              message: sendData.message,
              metadata: {
                preTranslationText: sendData.translateMsg
              }
            }

            // Analytic event to compare message (AdobeAnswer) is edited or not
            const copiedAnswer = getCopiedAdobeAnswer(contactID)

            if (!!copiedAnswer?.answer) {
              compareAdobeAnswerAndCCPMessage(copiedAnswer.answer, sendData.message, copiedAnswer.assistId)
            }

            const resp = await agentChatSession.sendMessage({
              contentType: isLargeMessage ? 'application/json' : 'text/plain',
              message: isLargeMessage ? JSON.stringify(largeMsgPayload) : sendData.message
            })

            let upsertMessageMetaData = messageMetaData[cnn.contactId] || ''
            if (!upsertMessageMetaData) {
              upsertMessageMetaData = messageMetaDataState()
              upsertMessageMetaData.contactId = cnn.contactId
            }
            upsertMessageMetaData.messageId = resp.data.Id
            upsertMessageMetaData.isRead = false
            upsertMessageMetaData.isDelivered = false
            store.dispatch(setMessageMetaData(upsertMessageMetaData))

            const translateMsg = sendData.translateMsg || ''
            if (upsertData.translation && translateMsg) {
              const oldUpsertTrMsg = upsertData.upsertTrMsg || {}
              const newUpsertTrMsg = {
                [resp.data.Id]: {
                  Content: translateMsg
                }
              }
              upsertData.upsertTrMsg = { ...oldUpsertTrMsg, ...newUpsertTrMsg }
              store.dispatch(setAutoTranslation(upsertData))
            }
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  static async sendAttachment(contactID: string, file: File) {
    try {
      connect.agent(async (agent) => {
        const contact = agent.getContacts().find((c) => c.contactId === contactID)
        if (contact) {
          const cnn = contact.getAgentConnection() as connect.ChatConnection
          if (cnn) {
            try {
              const agentChatSession = await cnn.getMediaController()
              const attachmentResp = await agentChatSession.sendAttachment({
                attachment: file
              })
              if (attachmentResp.httpResponse.statusCode === 200) {
                setTimeout(() => {
                  store.dispatch({ type: 'SET_ON_UPLOADING', payload: { contactId: cnn.contactId, uploading: false, apiUploadError: '' } })
                }, 3000)
              }
            } catch (error: any) {
              store.dispatch({ type: 'SET_ON_UPLOADING', payload: { contactId: cnn.contactId, uploading: false, apiUploadError: error?.message || 'Something went wrong.' } })
            }
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  static async downloadAttachment(contactID: string, item: any) {
    const id = item.Attachments[0].AttachmentId
    try {
      connect.agent(async (agent) => {
        const contact = agent.getContacts().find((c) => c.contactId === contactID)
        if (contact) {
          const cnn = contact.getAgentConnection() as connect.ChatConnection
          if (cnn) {
            const agentChatSession = await cnn.getMediaController()
            const awsSdkResponse = await agentChatSession.downloadAttachment({
              attachmentId: id
            })
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(awsSdkResponse, item.Attachments[0].AttachmentName)
            } else {
              const a = document.createElement('a')
              document.body.appendChild(a)
              const url = window.URL.createObjectURL(awsSdkResponse)
              a.href = url
              a.download = item.Attachments[0].AttachmentName
              a.click()
              setTimeout(() => {
                window.URL.revokeObjectURL(url)
                document.body.removeChild(a)
              }, 0)
            }
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  static async closeContact(contactID: string) {
    try {
      connect.agent(async (agent) => {
        const { language: lang } = store.getState().preferences
        const contact = agent.getContacts().find((c) => c.contactId === contactID)
        if (contact) {
          const { conversations } = store.getState().contactCenter
          const cloneConversations = [...conversations]
          var conversation: any = cloneConversations.find((x) => x.ocPlatformData.chatInfo.currentContactId === contactID)

          if (conversation) {
            conversation.ocPlatformData.chatInfo.disconnectReason = 'AgentConsole'
          }
          store.dispatch(setConversations(cloneConversations))
          const cnn = contact.getAgentConnection() as connect.ChatConnection
          if (cnn) {
            cnn.destroy()
            const payload = {
              attributes: {
                closedFrom: 'AgentConsole'
              },
              contactId: contactID
            }
            await updateContactAttributes(payload)
            const data = localization[lang].CONVERSATION_CLOSED
            store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'closed', data } })
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  static closeTab() {
    try {
      for (const contact of agentObject.getContacts()) {
        const cnn = contact.getAgentConnection() as connect.ChatConnection
        if (cnn) {
          cnn.destroy()
        }
      }
      if (agentObject != null) {
        const states = agentObject.getAgentStates()
        const offlineState = states.filter((state: { name: string }) => state.name === 'Offline')[0]
        // Change agent state to offline when browser tab is closed
        agentObject.setState(offlineState, {
          success() {
            console.log('Agent Status changed to Offline')
          },
          failure() {
            console.log('Failed to set agent status to Offline')
          }
        })
      }
      fetch(`${env_variables.CCP_URL}/logout`, { credentials: 'include', mode: 'no-cors' }).then(() => {
        const eventBus = connect.core.getEventBus()
        eventBus.trigger(connect.EventType.TERMINATE)
        this.deleteAuthCookies()
      })
    } catch (error) {
      console.error(error)
    }
  }

  static async logOut() {
    try {
      connect.agent(async (agent) => {
        for (const contact of agent.getContacts()) {
          const cnn = contact.getAgentConnection() as connect.ChatConnection
          if (cnn) {
            cnn.destroy()
            await new Promise((res) => setTimeout(res, 1000))
          }
        }
        this.setStatus(AGENT_OFFLINE_STATUS)
      })
      await new Promise((res) => setTimeout(res, 3000))
      fetch(`${env_variables.CCP_LOGOUT_URL}/logout`, { credentials: 'include', mode: 'no-cors' }).then(() => {
        const eventBus = connect.core.getEventBus()
        eventBus.trigger(connect.EventType.TERMINATE)
        this.deleteAuthCookies()
      })
    } catch (error) {
      console.error(error)
    }
  }

  static deleteAuthCookies = () => {
    var cookies = document.cookie?.split('; ')
    for (var c = 0; c < cookies.length; c++) {
      var hostName = window.location.hostname?.split('.')
      while (hostName.length > 0) {
        var cookieBase = encodeURIComponent(cookies[c]?.split(';')[0]?.split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + hostName?.join('.') + ' ;path='
        var path = location.pathname?.split('/')
        document.cookie = cookieBase + '/'
        while (path.length > 0) {
          document.cookie = cookieBase + path?.join('/')
          path?.pop()
        }
        hostName?.shift()
      }
    }
  }

  static isLogoutAllowed() {
    let isLogoutAllowed = true
    const { language } = store.getState().preferences
    const lang = language || 'en'
    try {
      if (store.getState().contactCenter.conversations.length !== 0) {
        const { conversations } = store.getState().contactCenter
        conversations.forEach((conversation: any) => {
          if (!isLogoutAllowed) {
            return isLogoutAllowed
          }
          if (conversation.ocPlatformData.chatInfo.status !== CONVERSATION_CLOSED_STATUS) {
            alert(localization[lang].LOGOUT_ALERT)
            isLogoutAllowed = false
            return isLogoutAllowed
          }
        })
      }
      return isLogoutAllowed
    } catch (error) {
      isLogoutAllowed = false
      return isLogoutAllowed
    }
  }

  static getAgentStatus() {
    if (agentObject !== null) {
      return { currentState: agentObject.getStatus().name, nextState: agentObject.getNextState() ? agentObject.getNextState().name : '' }
    }
    return AGENT_DEFAULT_STATUS
  }

  static async clearConversation(c: connect.Contact) {
    const ctr = c.getAttributes()
    const timers = store?.getState()?.contactCenter?.timersRTS
    clearInterval(timers?.[c.contactId])
    store.dispatch({ type: 'REMOVE_RTS_TIMER', payload: c.contactId })
    try {
      const contactId = c.contactId || ''
      if (!contactId || closeConvsId[contactId]) return
      closeConvsId[contactId] = contactId
      const { conversations, conversationSummaries } = store.getState().contactCenter
      console.log('clearConversation', contactId, JSON.parse(JSON.stringify(conversations)), closeConvsId)
      const cloneConversations = [...conversations]
      var conversation: any = cloneConversations.find((x) => x.ocPlatformData.chatInfo.currentContactId === c.contactId)
      const currentContactId = conversation?.ocPlatformData?.chatInfo?.currentContactId || ''
      const initialContactId = conversation?.ocPlatformData?.chatInfo?.initialContactId || ''
      const timerInfo = Utility.getActiveTimerInfo()
      const activeTimerInfo = timerInfo[currentContactId] || {}
      if (conversation) {
        conversation.ocPlatformData.chatInfo.status = 'Closed'
        const agentStartTime = activeTimerInfo?.chatAgentStartTime || conversation?.ocPlatformData?.chatInfo?.chatStartTime
        conversation.ocPlatformData.chatInfo.duration = Math.round((new Date().getTime() - new Date(agentStartTime).getTime()) / 1000)
        conversation.ocPlatformData.chatInfo.updateDate = new Date().toISOString()
      }
      store.dispatch(setConversations(cloneConversations))
      if (ctr?.disconnectReason?.value === 'CUSTOMER_DISCONNECT') {
        store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'closedCustomer', data: initialContactId } })
      } else if (ctr?.disconnectReason?.value === 'TRANSFER') {
        store.dispatch({ type: 'SET_TRANSFER_IN_PROGRESS_ID', payload: '' })
      }

      // call case notes api when conversation is closed
      const isCaseNotes = store.getState().contactCenter?.caseNotesEligible || false
      const agentDetails = store.getState().contactCenter?.agentDetails || {}
      const callStatus = conversation?.ocPlatformData?.callInfo?.status || ''
      const isChatToPhone = conversation?.ocPlatformData?.callInfo?.origin === 'chat-to-phone'
      const queuePathLength = conversation?.jcAuthData?.queuePath?.value?.split(',').length || 0
      var sendCaseNotesResponse = {}
      if (isCaseNotes && conversation) {
        if (isChatToPhone && queuePathLength === 1 && callStatus === 'Closed') {
          // Chat to Phone for first agent
          console.log('Case Notes triggered - C2P Flow - First Agent')
          sendCaseNotesResponse = await sendCaseNotes(conversation, agentDetails)
        } else if (isChatToPhone && queuePathLength > 1) {
          //Chat to Phone for second agent
          console.log('Case Notes triggered - C2P Flow - Second Agent')
          sendCaseNotesResponse = await sendCaseNotes(conversation, agentDetails)
        } else if (!isChatToPhone) {
          console.log('Case Notes triggered - Normal Flow') //Remaining Scenario
          sendCaseNotesResponse = await sendCaseNotes(conversation, agentDetails)
        } else {
          console.log('Case Notes not triggered as call is active - C2P')
        }
        console.log('sendCaseNotesResponse', sendCaseNotesResponse)
        const { miloUpdate = [] } = sendCaseNotesResponse || {}
        if (miloUpdate.length > 0 && miloUpdate?.[0]?.updatedToMilo) {
          store.dispatch({ type: 'SET_MILO_UPDATED', payload: { contactId: currentContactId, updatedToMilo: true } })
        }
      }

      //This part is moved to ICH for Closed Conversation summary part

      // const transcript = conversation.ocPlatformData.chatTranscript
      // const queue = conversation?.jcAuthData?.currentQueue?.value
      // const { language: { value: originalQueueLangCode = '' } = {}, region: { value: originalQueueRegion = '' } = {} } = conversation?.jcAuthData;
      // const originalLanguageKey = `${originalQueueLangCode}_${originalQueueRegion}`;
      // const originalQueueLanguage = queueLanguages[originalLanguageKey] || 'English';
      // const finalSummary = true;

      // if(!conversationSummaries?.[initialContactId]?.final &&
      //   (ctr?.disconnectReason?.value === 'CUSTOMER_DISCONNECT' ||
      //     ctr?.disconnectReason?.value === 'IDLE' ||
      //     ctr?.disconnectReason?.value === 'OVERDUE' ||
      //     ctr?.disconnectReason?.value === 'AgentConsole' ||
      //     conversation.ocPlatformData.chatInfo.disconnectReason === 'AgentConsole' )
      //   ) {
      //   ContactCenter.generateConversationSummary(initialContactId, transcript, queue, finalSummary, originalQueueLanguage)
      // }

      // Remove the event listener
      const listener = this.eventListeners.get(contactId)
      if (listener) {
        document.removeEventListener('visibilitychange', listener)
        this.eventListeners.delete(contactId)
      }
      // Remove the notification
      this.notifications.delete(contactId)

      ContactCenter.saveActiveChatIds()
      c.clear({
        success() {},
        failure(err) {
          console.log('contact clear failed', err)
        }
      })
    } catch (error) {
      console.log('clearConversation error', error)
    }
  }

  static async getOngoingConversationForAgent(agentId: string) {
    try {
      const ongoingConversationResponse = await fetchOngoingConversations(agentId)
      const items = ongoingConversationResponse.data.data.Items
      const existingConversations = [...store.getState().contactCenter.conversations]

      items.forEach((x: any) => {
        const conv = ContactCenter.serializeAttributes(x)
        const foundIndex = existingConversations?.findIndex((x) => x?.ocPlatformData?.chatInfo?.currentContactId === x?.contactId)
        if (foundIndex < 0) existingConversations.push(conv)
      })
      store.dispatch(setConversations(existingConversations))
    } catch (error) {
      console.log(error)
    }
  }

  static serializeAttributes(x: any) {
    const jcAuthData: any = {}
    const attributes = JSON.parse(x.contactAttributes)
    if (!attributes?.agentId && x?.agentId) {
      jcAuthData['agentId'] = { name: 'agentId', value: x.agentId }
      jcAuthData['firstName'] = { name: 'firstName', value: x.customerFirstName }
      jcAuthData['lastName'] = { name: 'lastName', value: x.customerLastName }
    }
    if (x?.linkedConversationId) {
      jcAuthData['linkedConversationId'] = { name: 'linkedConversationId', value: x.linkedConversationId }
    }
    if (attributes && attributes !== {}) {
      for (const key in attributes) {
        jcAuthData[key] = {
          name: key,
          value: attributes[key]
        }
      }
    }
    const conv = {
      jcAuthData: jcAuthData,
      ocPlatformData: {
        chatInfo: {
          initialContactId: x.conversationId,
          currentContactId: x.contactId,
          status: 'Closed',
          queueName: x.queue,
          chatStartTime: x.conversationStartDate,
          duration: x.duration || 0,
          updateDate: x.updateDate
        },
        chatTranscript: null
      }
    }
    return conv
  }

  static async calculateCsat(agentId: string) {
    try {
      const response = await fetchCsatForAgent(agentId)
      if (response && response.data && response.data.data) {
        const data = response.data.data.Items
        // filter data that has 0 or undefined values
        const totalCount = [...data].filter((item: any) => item.csat !== 0 && item.csat !== undefined).length
        const csatCount = [...data].filter((item: any) => item.csat !== 0 && item.csat >= 80 && item.csat !== undefined).length
        if (totalCount !== 0) {
          // let total = 0
          // for (var i = 0; i < csatList.length; i++) {
          //   total += parseInt(csatList[i])
          // }
          const averageCsat = Math.round((csatCount / totalCount) * 100)
          const { agentDetails } = store.getState().contactCenter
          store.dispatch(setAgentDetails({ ...agentDetails, averageCsat }))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  static getSkillsFromFloodgate = async (url?: string = env_variables.FG_ENDPOINT_CONVO_SUMMARY) => {
    try {
      const {
        agentDetails: { ldap: ldap }
      } = store.getState().contactCenter
      const floodgateResponse: any = await getFeatureFlags({ agentId: ldap }, url)
      const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
      return featureFlags
    } catch (error) {
      console.log('getSkillsFromFloodgate error', error)
      return []
    }
  }

  static getConversationSummarySkills = async () => {
    try {
      const localData: any = localStorage.getItem('conversationSummarySkills')
      const skills = JSON.parse(localData) || undefined
      if (skills == undefined) {
        console.log('Conversation summary: cache not present,so making FG call')
        const featureFlags = await ContactCenter.getSkillsFromFloodgate()
        localStorage.setItem('conversationSummarySkills', JSON.stringify(featureFlags))
        return featureFlags
      } else {
        console.log('Conversation summary: return from cache')
        return skills
      }
    } catch (e) {
      console.log('Conversation summary: get allowed skills from cache fail', e)
      return []
    }
  }

  static getAdobeAnswersSkills = async () => {
    try {
      const localData: any = localStorage.getItem('adobeAnswersSkills')
      const skills = JSON.parse(localData) || null
      if (skills == null) {
        console.log('Adobe Answers: cache not present,so making FG call')
        const featureFlags = await ContactCenter.getSkillsFromFloodgate(env_variables.FG_ENDPOINT_AA)
        localStorage.setItem('adobeAnswersSkills', JSON.stringify(featureFlags))
        return featureFlags
      } else {
        console.log('Adobe Answers FG skills returned from cache')
        return skills
      }
    } catch (e) {
      console.log('Adobe Answers: get allowed skills from cache fail', e)
      return []
    }
  }

  static async generateConversationSummary(contactId: any, transcript: Array<{ [key: string]: any }>, queue: any, final: boolean = false, language: string) {
    if (!contactId) return

    // Removing Floodgate check for Conversation Summary since it is enabled for all agents and queues
    // CS enable check w.r.t queue (Global Queue Enablement)

    // const isEnabledByQueue = await enableConvSummaryByQueue(queue)

    // CS enable check w.r.t agentId, if it is not enabled via queue (Only for UAT | AB Testing)
    // if (!isEnabledByQueue) {
    //   const allowedSkills = await ContactCenter.getConversationSummarySkills()
    //   const allowedSkillsInLC = allowedSkills.map((skill) => skill.toLowerCase())
    //   const isEnabledByAgentId = allowedSkillsInLC.includes(queue.toLowerCase())

    //   console.log('Conversation Summary Enabled By AgentId : %s ', isEnabledByAgentId)

    //   if (!isEnabledByAgentId) return
    // }

    const filteredTranscript = transcript.filter((message) => {
      return (
        !message?.Content?.includes('CUSTOM_SYSTEM_MESSAGE') &&
        !message?.Content?.includes('IGNORE_CONTACT_FLOW_INITIALIZED') &&
        !message?.Content?.includes('IGNORE_') &&
        message.Content != null &&
        message.DisplayName !== 'SYSTEM_MESSAGE' &&
        message.ContentType === 'text/plain' &&
        message.Type === 'MESSAGE'
      )
    })

    if (filteredTranscript.length === 0) return

    const path = Utility.getPathFromTranscript(transcript)

    if (!path.length) return

    const loadingSummary = {
      main_issue: 'Loading data...',
      conversation_points: ['Loading data...'],
      links: ['Loading data...']
    }

    store.dispatch(setConversationSummary({ contactId, summary: loadingSummary, path }))
    const {
      agentDetails: { ldap: ldap }
    } = store.getState().contactCenter
    const params = {
      ldap,
      path: path.map(({ DisplayName }) => DisplayName).join(' -> '),
      contactId,
      queue,
      final,
      language
    }
    const urlParams = new URLSearchParams(window.location.search)
    const questionsFeatureFlag = urlParams.get(FEATURE_FLAG_AA_Q)
    const flags = this.getFastFollowFlags()
    const summary = await fetchConversationSummary(transcript, params, flags?.FAST_FOLLOWS_GENAI_SUGGESTED_QUESTIONS === true || questionsFeatureFlag === 'true')

    if (summary) {
      store.dispatch(setConversationSummary({ contactId, summary, path, final }))
    }

    // send the conversation summary analytics to CTR
    await updateCTRForConversationSummaryAnalytics(contactId, summary, false)
  }

  /**
   * Method that fetches/generates sentiments from the sentiment processor backend
   * This handles the edge case that BOT chats have the same contactId as the next leg in the conversation:
   *
   * To do so we need to append -BOT to the contactId relating to the BOT chat to differentiate from the next leg of the conversation which will have the same contactId
   * The backend needs specific logic to handle BOT segments differently than normal ones and mark those messages as botProcessed so it doesn't reprocess those messages when evaluating the next leg of the conversation
   *
   * FLOW:
   *     1. BOT(c1)->AGENT1(c1): Customer talks with the BOT and then is transferred to Agent1 (initialContactId = c1, currentContactId = c1). OAC gets contactId1(c1)(initialContactId and currenctContactId are the same) from the agentPath and sends it to the backend with -BOT appended for the BOT in the path. Backend then knows it is a BOT leg and fetches all messages by the contactId, marks them as botProcessed, generates a sentiment, and returns/saves the result by the c1-BOT id
   *     2. BOT(c1)->AGENT1(c1)->AGENT2(c2): Customer talks with agent1 and is transferred to agent2 (initialContactId = c1, currentContactId = c2). Agent2 OAC gets contactId1(c1)(BOT), contactId1(c1)(agent1) from the agentPath which includes BOT and Agent1. It sends c1-BOT and c1 to the backend. Backend gets existing sentiment for c1-BOT and fetches messages using c1 where !botProcessed and generates a sentiment. It returns/saves both sentiments keyed by c1 and c1-BOT
   *     3. BOT(c1)->AGENT1(c1)->AGENT2(c2)->AGENT3(c3): Customer talks with agent2 and is transferred to agent3 (initialContactId = c1, currentContactId = c3). Agent3 OAC gets contactId1(c1), contactId1(c1), and contactId2(c2) from the agent path and sends c1-BOT, c1, c2 to the backend which fetches the existing sentiment for c1-BOT and c1 and fetches messages using c2 and generates a sentiment. It returns/saves all sentiments keyed by c1-BOT, c1, and c2
   *
   * @param contactId current contactId
   * @param transcript the complete chat transcript
   */
  static async generateContactLegSentiments(contactId: string, transcript: any[], initialContactId: string) {
    store.dispatch({ type: 'SETCONVERSATIONSENTIMENTLOADING', payload: { contactId, loading: true } })
    store.dispatch({ type: 'SETCONVERSATIONSENTIMENTERRORS', payload: { contactId, error: undefined } })
    const agents = Utility.getPathFromTranscript(transcript)
    // need to append any contactIds for BOT chats with -BOT
    const contactIdsOfConversation = agents.map((agent) => (agent.DisplayName === 'BOT' ? `${agent.ContactId}-BOT` : agent.ContactId))
    const sentiments = fetchContactLegSentiments(contactIdsOfConversation, initialContactId)
      .then((res) => {
        store.dispatch(setConversationSentiments(res))
      })
      .catch((err) => {
        store.dispatch({ type: 'SETCONVERSATIONSENTIMENTERRORS', payload: { contactId, error: err?.message ?? 'Internal Error' } })
      })
      .finally(() => {
        store.dispatch({ type: 'SETCONVERSATIONSENTIMENTLOADING', payload: { contactId, loading: false } })
      })
  }

  /**
   * Simple method the fetches the real time sentiment from the backend and sets redux state
   * @param contactId
   */
  static async generateRealTimeSentiment(
    contactId: string,
    minCharacterThreshold: number,
    agentLdap: string,
    agentName: string,
    queueName: string,
    managerLdap: string,
    initialContactId: string,
    isAgentEligible: boolean
  ) {
    store.dispatch({ type: 'SET_RT_SENTIMENT_ERRORS', payload: { contactId, error: undefined } })
    const { previousLegInfo = [], currentConversation: { ocPlatformData: { callInfo: { origin = '', status: callStatus = '' } = {} } = {} } = {} } = store.getState().contactCenter
    const chatToPhoneActiveCall = origin === 'chat-to-phone' && callStatus === 'Active'
    const previousContactLegInfo = previousLegInfo?.[contactId] || []

    fetchContactRealTimeSentiments(
      contactId,
      initialContactId,
      minCharacterThreshold,
      agentLdap,
      agentName,
      queueName,
      managerLdap,
      previousContactLegInfo,
      isAgentEligible,
      chatToPhoneActiveCall
    )
      .then((res) => {
        store.dispatch(setContactRealTimeSentiment(res))
      })
      .catch((err) => {
        store.dispatch({ type: 'SET_RT_SENTIMENT_ERRORS', payload: { contactId, error: err?.message ?? 'Internal Error' } })
      })
  }

  /**
   * Method that initiates the real time sentiment timer and generates a sentiment in regular increments
   *   - if there is no timer in localStorage, initiate a new timer for this contact
   *     - setInterval() using the pollingFrequency (from floodgate)
   *     - save the interval timer id in redux to be cleared later
   *     - when the interval callback fires, save the convo timestamp in localStorage for the contact
   *     - callback queries the sentiment processor backend for the real time leg cumulative sentiment AND trendline sentiment and sets it in redux
   *   - if there is a timer in localStorage for the contact, check if more time has elapsed than the pollingFrequency
   *     - if so, immediately query the backend for the sentiment and set in redux and start the interval
   *     - if not, wait the remaining time then query for sentiment and start a new timer/interval
   *
   * In the clearContact() method we should:
   *   - clear any interval timer associated with the contact
   *
   * @param contactId
   * @param pollingFrequency the time in seconds between each call to get the sentiment
   * @returns void
   */
  static async initializeRealTimeSentimentTimer(
    contactId: string,
    pollingFrequency: number,
    minCharacterThreshold: number,
    agentLdap: string,
    agentName: string,
    queueName: string,
    managerLdap: string,
    initialContactId: string,
    transcript: any[],
    isAgentEligible: boolean
  ) {
    const frequencyInMS = pollingFrequency * 1000
    const RTSTimers = Utility.getRTSentimentTimersFromLocalStorage()
    const timer = RTSTimers?.[contactId]
    const { previousLegInfo } = store.getState().contactCenter
    if (!previousLegInfo?.[contactId]) {
      const previousContactLegInfo = Utility.getPathFromTranscript(transcript)
      const contactIdsOfConversation = previousContactLegInfo?.map((info) => info?.ContactId)
      store.dispatch({ type: 'SET_PREVIOUS_LEG_INFO_FOR_CURRENT_CONTACT', payload: { contactId, previousLegInfo: contactIdsOfConversation } })
    }
    const callback = () => {
      ContactCenter.generateRealTimeSentiment(contactId, minCharacterThreshold, agentLdap, agentName, queueName, managerLdap, initialContactId, isAgentEligible)
      // save the timestamp in localStorage
      Utility.setRTSentimentTimersInLocalStorage(contactId, Date.now())
    }
    const intervalHandler = (intervalId: NodeJS.Timeout) => {
      store.dispatch({ type: 'SET_RTS_TIMERS', payload: { contactId: contactId, intervalId } })
    }

    if (!timer) {
      delayOrInvokeInterval(callback, intervalHandler, frequencyInMS)
    } else if (Date.now() - Number(timer) > frequencyInMS) {
      delayOrInvokeInterval(callback, intervalHandler, frequencyInMS, 0)
    } else {
      const timeRemaining = frequencyInMS - (Date.now() - Number(timer))
      delayOrInvokeInterval(callback, intervalHandler, frequencyInMS, timeRemaining)
    }
  }

  static async getTranscript(contactId: string) {
    const x = await fetchTranscripts(contactId)
    return x
  }

  static voiceOrCallBack = () => {
    let contact = agentObject?.getContacts(connect.ContactType.VOICE) || []
    if (!contact?.length) {
      contact = agentObject?.getContacts(connect.ContactType.QUEUE_CALLBACK) || []
    }
    return contact
  }

  static async dtmfInput(digits: any) {
    const contact = ContactCenter.voiceOrCallBack()
    const cnn = (contact[0]?.getAgentConnection() as connect.VoiceConnection) || ''
    if (cnn) {
      cnn.sendDigits(digits, {
        success: function () {
          console.log('DTMF Successful', digits)
        },
        failure: function (err: any) {
          console.log('DTMF failure', err)
        }
      })
    }
  }

  static async muteCall(eventData: any) {
    try {
      const { bannerInfo } = store.getState().contactCenter
      const updateBannerInfo = { ...bannerInfo }
      const contact = ContactCenter.voiceOrCallBack()
      const voiceConnection = (contact[0]?.getAgentConnection() as connect.VoiceConnection) || ''
      const isMute = eventData?.mute || false
      if (!voiceConnection || !agentObject) return
      if (updateBannerInfo?.callConnections?.length > 1) {
        const successFailCallback = {
          success: () => {},
          failure: (err: any) => {
            console.log(`Error handle during mute/unmute`, err)
          }
        }
        isMute ? voiceConnection.muteParticipant(successFailCallback) : voiceConnection.unmuteParticipant(successFailCallback)
      } else {
        isMute ? agentObject.mute() : agentObject.unmute()
      }
      updateBannerInfo.muteStartDate = isMute ? new Date() : ''
      store.dispatch(setBannerInfo(updateBannerInfo))
      Utility.setCallInfoData({ isMute })
    } catch (e) {
      console.log('mute Call failed', e)
    }
  }

  static async endCall(isAgentEnded: boolean = false) {
    try {
      store.dispatch(IncomingCall({ openPopup: '' }))
      const contact = ContactCenter.voiceOrCallBack()
      const timerInfo = Utility.getActiveTimerInfo()
      const voiceConnection = (contact[0]?.getAgentConnection() as connect.VoiceConnection) || ''
      const { currentConversation } = store.getState().contactCenter
      const { isVpnConnected } = store.getState().preferences
      const conv = { ...currentConversation }
      const {
        jcAuthData: { handoffCount: { value: handoffCount = 0 } = {} } = {},
        ocPlatformData: {
          callInfo: { singleActiveThirdPartyConnection = '', connections = 0, multipleAgentsConnected = false, currentContactId = '', origin = '', linkedConversationId = '' } = {}
        } = {}
      } = conv
      const contactId = origin === CALLEVENTS.CHAT_TO_PHONE ? linkedConversationId : currentContactId
      console.log('End Call - voiceConnection', voiceConnection?.contactId, 'isVpnConnected', isVpnConnected)
      if (!isVpnConnected && voiceConnection?.contactId && !multipleAgentsConnected) await Utility.updateClosedDueToVpnDisconnect(voiceConnection?.contactId)
      //Update to Context data regarding the info where conversation is closed by agent
      if (isAgentEnded && !multipleAgentsConnected) {
        await Utility.updateContextClosedByAgent()
      }
      if (!voiceConnection) return
      voiceConnection?.destroy()

      if (timerInfo[contactId]?.voiceAgentStartTime) {
        timerInfo[contactId]['voiceAgentEndTime'] = new Date()
        Utility.setActiveTimerInfo(timerInfo)
      }
      store.dispatch(setOutBoundCall(false))

      if (multipleAgentsConnected) conv.ocPlatformData.callInfo['multipleAgentsConnected'] = false
      store.dispatch(setCurrentConversation(conv))
      if (connections > 1 && singleActiveThirdPartyConnection) {
        Utility.upsertHandOffCount({ handoffCount, currentContactId })
      }
    } catch (e) {
      console.log('End call error', e)
    }
  }

  static async removeParticipant() {
    try {
      const contact = ContactCenter.voiceOrCallBack()
      const connections = ((contact[0]?.getConnections() as connect.VoiceConnection) || []).map((i: any) => {
        return i.connectionId
      })
      const initialConnection = (contact[0]?.getInitialConnection() as connect.VoiceConnection) || ''
      const initialConnectionId = initialConnection.connectionId
      const agentConnection = (contact[0]?.getAgentConnection() as connect.VoiceConnection) || ''
      const agentConnectionId = agentConnection.connectionId
      const thirdPartyConnection = connections?.filter((item: any) => item !== initialConnectionId && item !== agentConnectionId) || []
      // const agentName = thirdPartyConnection[0].getQuickConnectName();
      // console.log("Third party agent",agentName);
      if (thirdPartyConnection.length > 0) {
        const allConnections = (contact[0]?.getConnections() as connect.VoiceConnection) || []
        const connection = allConnections.find((i: any) => i.connectionId === thirdPartyConnection[0])
        connection?.destroy()
        store.dispatch({ type: 'PARTICIPANT_LEFT_THE_CALL', payload: true })
        const { currentConversation } = store.getState().contactCenter
        currentConversation.ocPlatformData.callInfo['multipleAgentsConnected'] = false
      }
    } catch (e) {
      console.log('End call error', e)
    }
  }

  static async clearContact() {
    try {
      const contact = ContactCenter.voiceOrCallBack()
      const {
        jcAuthData: { queuePath: { value: customQueuePath = '' } = {} } = {},
        ocPlatformData: { chatInfo: { status: customChatStatus = '' } = {}, callInfo: { currentContactId = '', origin: customOrigin } = {} } = {}
      } = Utility.getActiveCallLeg()
      let clearContactCallLeg: any = ''
      const conversation = Utility.getActiveCallLeg()
      if (currentContactId) {
        clearContactCallLeg = contact?.find((i) => i.contactId === currentContactId)
      }
      const log = `CONTACT FLOW: clear contact. currentContactId ${currentContactId} clearContactCallLeg ${clearContactCallLeg} contact ${contact}`
      connect.getLog().info('%s', log)
      console.log(log)
      const nextState = sessionStorage.getItem('agent_nextStatus') || ''
      if (nextState) store.dispatch(setCurrentStatus(nextState, 'agent'))
      if (clearContactCallLeg) clearContactCallLeg?.clear()
      if (!clearContactCallLeg && contact.length) contact[0].clear()
      store.dispatch(endCall())
      store.dispatch(setAcw(false))
      const isCaseNotes = store.getState().contactCenter?.caseNotesEligible || false
      const agentDetails = store.getState().contactCenter?.agentDetails || {}
      const chatStatus = customChatStatus
      const isChatToPhone = customOrigin === 'chat-to-phone'
      const queuePathLength = customQueuePath?.split(',').length || 0
      if (isChatToPhone && chatStatus === 'Closed' && queuePathLength === 1 && isCaseNotes) {
        console.log('Case Notes triggered - C2P Flow - First Agent')
        await sendCaseNotes(conversation, agentDetails)
      }
    } catch (e) {
      console.error('Clear contact catch block', e)
    }
  }

  static async outboundCall(data: any, eventData: any) {
    const { currentConversation } = store.getState().contactCenter
    const {
      jcAuthData: { handoffCount: { value: _handoffCount = 0 } = {} } = {},
      ocPlatformData: { callInfo: { connections = 0 } = {}, chatInfo: { currentContactId = '' } = {} } = {}
    } = currentConversation
    const payload = data
    const { customerEndpoint: custEndpoint } = data
    const metadataUpdated = await outboundMetadata(payload)
    if (metadataUpdated) {
      try {
        connect.agent(async (agent) => {
          const endpoint = connect.Endpoint.byPhoneNumber(custEndpoint)
          const contactToTransfer = agent.getContacts(connect.ContactType.VOICE).find((a) => a.contactId === currentContactId)
          let queueARN = agent.getRoutingProfile().queues.find((item: any) => item.name === currentConversation?.jcAuthData?.currentQueue?.value)?.queueARN
          if (!queueARN) {
            const routingProfile = agent.getRoutingProfile().defaultOutboundQueue
            queueARN = eventData.queueARN ? eventData.queueARN : routingProfile.queueARN
          }
          const { queueName: outboundQueueName = '' } = eventData
          if (connections > 1) {
            Utility.addMultipartyNewConnection(endpoint, contactToTransfer)
          } else {
            agent.connect(endpoint, {
              queueARN: queueARN,
              async success() {},
              failure(err) {
                console.log('outboundCall failure ===> ', err)
                const { language: lang } = store.getState().preferences
                const data = localization[lang].SOFTPHONE_ERROR['connect-softphone-error-invalid-outbound-configuration-message']
                store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'error', data } })
              }
            })
          }
        })
      } catch (error) {
        console.log('outboundCall try block fail ===> ', error)
      }
    }
  }
  static acceptIncomingCall() {
    const logString = `ACCEPTING_CALL : Accept button is Clicked `
    connect.getLog().info('%s', logString)
    let contact = agentObject?.getContacts(connect.ContactType.VOICE) || []
    if (!contact?.length) contact = agentObject?.getContacts(connect.ContactType.QUEUE_CALLBACK) || []
    const initialContactId = contact[0]?.getInitialContactId() || ''
    const currentContactId = contact[0]?.getContactId() || ''
    const phoneNumber = contact[0]?.getInitialConnection()?.getEndpoint()?.phoneNumber || ''
    contact[0]?.accept({
      success: function () {
        const successLog = `ACCEPTING_CALL : call accepted and executed success block`
        connect.getLog().info('%s', successLog)
        const attributes = contact[0]?.getAttributes() || {}
        const { agentDetails } = store.getState().contactCenter
        const { ldap = '' } = agentDetails
        const date = new Date()
        const callStartTime = date.toISOString()
        const {
          origin: { value: incomingOrigin = '' } = {},
          countryCode: { value: incomingCountryCode = '' } = {},
          customerId: { value: incomingCustomerId = '' } = {},
          linkedConversationId: { value: linkedConversationId = '' } = {},
          transferCount: { value: _transferCount = 0 } = {},
          previousAgentId: { value: _previousAgentId = '' } = {},
          transferType: { value: _transferType = '' } = {},
          handoffCount: { value: _handoffCount = 0 } = {}
        } = attributes
        const newHandOffCount = Number(_handoffCount).toString()
        const chatInitialContactId = incomingOrigin === 'bot-to-phone' ? initialContactId : linkedConversationId || initialContactId
        const chatCurrentContactId = incomingOrigin === 'bot-to-phone' ? currentContactId : linkedConversationId || currentContactId
        attributes['handoffCount'] = { name: 'handoffCount', value: newHandOffCount }
        const data = {
          jcAuthData: attributes,
          ocPlatformData: {
            callInfo: {
              origin: incomingOrigin,
              customerEndpoint: phoneNumber,
              callStartTime: callStartTime,
              countryCode: incomingCountryCode,
              initialContactId: initialContactId,
              currentContactId: currentContactId,
              agentId: ldap,
              customerId: incomingCustomerId,
              status: 'Active',
              isMute: false,
              isHold: false,
              assignedAgent: true,
              handoffCount: newHandOffCount,
              isJoin: false,
              isSwap: false,
              isTransfer: false,
              linkedConversationId
            },
            chatInfo: {
              status: 'Active',
              initialContactId: chatInitialContactId,
              currentContactId: chatCurrentContactId
            },
            chatTranscript: []
          }
        }
        const timerInfo: any = Utility.getActiveTimerInfo()
        const contactId = incomingOrigin === CALLEVENTS.CHAT_TO_PHONE ? linkedConversationId : currentContactId
        timerInfo[contactId] = {
          chatAgentStartTime: new Date(),
          voiceAgentStartTime: new Date(),
          voiceAgentEndTime: ''
        }
        Utility.setActiveTimerInfo(timerInfo)
        if (_transferCount > 0 && _transferType === CALLEVENTS.TRANSFER_TYPE.WARM) {
          data.ocPlatformData.callInfo['multipleAgentsConnected'] = true
          data.ocPlatformData.callInfo['assignedAgent'] = false
        } else if (_transferType === CALLEVENTS.TRANSFER_TYPE.COLD) {
          data.ocPlatformData.callInfo['multipleAgentsConnected'] = false
        }
        ContactCenter.UpsertVoiceContact(data)
      },
      failure: function (err) {
        const errorStr = `ACCEPTING_CALL : Accept button is Clicked but it endup with error`
        connect.getLog().error('%s', errorStr)
        console.log(err)
      }
    })
  }
  static async getTranslationTranscript(reqBody: any) {
    const x = await fetchTranslationTranscripts(reqBody)
    return x
  }
  static async transferConversation(transferObj: any) {
    const { currentConversation } = store.getState().contactCenter
    if (transferObj?.queue?.endpointARN) {
      transferObj?.queue?.endpointARN.split(':').forEach((element: any) => {
        if (element.indexOf('instance') !== -1) {
          // const arr = element.split('/')
          // let instanceId = arr[arr.findIndex((x: any) => x.indexOf('instance') !== -1) + 1]
        }
      })
    }
    const transCount = currentConversation?.jcAuthData?.transferCount?.value
      ? !isNaN(currentConversation?.jcAuthData?.transferCount?.value)
        ? parseInt(currentConversation?.jcAuthData?.transferCount?.value)
        : 0
      : 0
    const msgValue = transferObj.message.length > 1024 ? transferObj.message.slice(0, 1024) : transferObj.message
    const payload: any = {
      attributes: {
        transferNote: msgValue,
        transferNoteFound: 'true',
        transferCount: (transCount + 1).toString(),
        chatTransferCount: (transCount + 1).toString(),
        queuePath: currentConversation?.jcAuthData?.queuePath?.value ? `${currentConversation?.jcAuthData?.queuePath?.value}, ${transferObj.queue.name}` : transferObj.queue.name
      },
      contactId: currentConversation.ocPlatformData.chatInfo.currentContactId
    }
    if (transferObj.returnToAgent) {
      payload.attributes.returnToAgent = 'true'
    }

    // if (transferObj.transferToOverrideSuggestion) {
    //   payload.attributes.transferToQueue = 'true'
    //   payload.attributes.queueName = transferObj.queue.name
    // }

    if (transferObj.overrideSuggestion) {
      payload.attributes.overrideSuggestion = transferObj.overrideSuggestion
    }

    const updated = await updateContactAttributes(payload)
    const { language } = store.getState().preferences
    const lang = language || 'en'
    if (updated) {
      try {
        connect.agent(async (agent) => {
          const contactToTransfer = agent.getContacts(connect.ContactType.CHAT).find((a) => a.contactId === currentConversation.ocPlatformData.chatInfo.currentContactId)
          const { agentDetails } = store.getState().contactCenter
          const findQueueForArn = agentDetails?.skills?.find((s: any) => s.name === transferObj.queue.name)
          const transferObjNew = { ...transferObj.queue }
          if (!transferObjNew.endpointARN && findQueueForArn?.endpointARN) {
            transferObjNew['endpointARN'] = findQueueForArn?.endpointARN
            transferObjNew['type'] = findQueueForArn?.type || 'queue'
          }
          contactToTransfer?.addConnection(transferObjNew, {
            async success() {
              await new Promise((res) => setTimeout(res, 3000))
              store.dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'transfer', data: transferObj } })
              const { conversations } = store.getState().contactCenter
              const cloneConversations = [...conversations]
              const foundIndex = cloneConversations.findIndex((x) => x.ocPlatformData.chatInfo.currentContactId === currentConversation.ocPlatformData.chatInfo.currentContactId)
              if (foundIndex >= 0) {
                cloneConversations[foundIndex].jcAuthData.chatTransferCount = { name: 'chatTransferCount', value: (transCount + 1).toString() }
                cloneConversations[foundIndex].ocPlatformData.chatInfo.status = CONVERSATION_CLOSED_STATUS
                console.log('Transfer chat data', cloneConversations[foundIndex])
                transferChats[currentConversation.ocPlatformData.chatInfo.currentContactId] = currentConversation.ocPlatformData.chatInfo.currentContactId
                store.dispatch(setConversations(cloneConversations))
              }
            },
            failure(data) {
              console.log('transfer failed')
              transferObj.option === 'Queue' ? alert(localization[lang].TRANSFER_FAILED_QUEUE) : alert(localization[lang].TRANSFER_FAILED_AGENT)
              store.dispatch({ type: 'SET_TRANSFER_IN_PROGRESS_ID', payload: '' })
              if (transferChats[currentConversation?.ocPlatformData?.chatInfo?.currentContactId]) {
                delete transferChats[currentConversation.ocPlatformData.chatInfo.currentContactId]
              }
            }
          })
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      alert(localization[lang].TRANSFER_FAILED)
      store.dispatch({ type: 'SET_TRANSFER_IN_PROGRESS_ID', payload: '' })
    }
  }

  static async isNewCustomer(customerID: any): Promise<boolean | undefined> {
    try {
      let resp: any = await getConversationHistory(customerID)
      resp = resp?.data?.data?.Items.filter((i: any) => !i.disconnectReason || (i.disconnectReason && i.disconnectReason.toLowerCase() !== 'transfer'))
      return resp.length === 0
    } catch (error) {
      console.log('CH error', error)
      return true
    }
  }

  static async getCustomerEndpoint(chatId: any) {
    try {
      const resp: any = await getConversationId(chatId)
      const customerEndPoint = resp?.data?.data?.Items[0]?.customerEndpoint || ''
      return customerEndPoint
    } catch (error) {
      console.log('getCustomerEndpoint error', error)
      return ''
    }
  }

  static async saveActiveChatIds() {
    try {
      const { conversations } = store.getState().contactCenter
      if (conversations && conversations.length > 0) {
        const activeChatIds: string[] = []
        conversations.forEach((x: any) => {
          if (x.ocPlatformData.chatInfo.status !== CONVERSATION_CLOSED_STATUS) activeChatIds.push(x.ocPlatformData.chatInfo.currentContactId)
        })
        localStorage.setItem('activeChatIds', JSON.stringify(activeChatIds))
      }
    } catch (error) {
      console.log('saveActiveChatIds', error)
    }
  }

  static onSessionCheck() {
    setInterval(() => {
      try {
        const oktaToken: any = JSON.parse(localStorage.getItem('okta-token-storage') || '')
        if (oktaToken?.accessToken?.accessToken) {
          const tokenCreatedAt: any = JSON.parse(localStorage.getItem('okta-shared-transaction-storage') || '')
          let dateCreated: Number = 0
          Object.keys(tokenCreatedAt).forEach((item) => {
            dateCreated = tokenCreatedAt[item].dateCreated || 0
          })
          const popOutTime = Number(dateCreated) + 34200000 // add extra 9.5 hrs
          const currentTime = new Date().getTime()
          if (currentTime >= popOutTime && dateCreated) {
            store.dispatch({ type: 'SETOFFLINEINDICATOR', payload: { isOpen: true, type: 'AWS_TOKEN_EXPIRY' } })
          }
        }
      } catch (e) {
        console.log('onSessionCheck', e)
      }
    }, 1800000) // evenry 30 min
  }

  static getAllActiveContactStatus() {
    return agentObject?.getContacts() || []
  }

  static downloadCCPLogs() {
    connect.getLog().download()
  }
}
