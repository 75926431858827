/**
 * - Author: Kiran M
 * - This file is for extending string prototype instance for code reusability
 * - declare global requires an empty export {} at the bottom, and do not export any thing 
 * 
 */
declare global {
    interface String {
        /**
         * truncate the string with custom characters or symbols
         * @param maxLength - maximum length of characters to truncate string
         * @param truncateString - character sequence to truncate the string
         */
        truncate(maxLength: number, truncateString?: string): string;
    }
}

String.prototype.truncate = function (maxLength: number, truncateString: string): string {
    truncateString = truncateString || '...';
    return this.length < maxLength ? this.toString() : this.slice(0, maxLength) + truncateString;
}

export { };
