import env_variables from '../../../config/env_variables'
import { apiClient } from './axiosSettings'
import getAuthHeaders from './getAuthHeaders'
import { openDB } from 'idb'
import { useDispatch } from 'react-redux';

// Database initialization (shared instance)
const agentNotesDBPromise = openDB('AgentNotesDB', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('AgentNotes')) {
      db.createObjectStore('AgentNotes', { keyPath: 'contactId' })
    }
  },
})

export async function cleanAgentNotesDB(chatId: string): Promise<void> {
  try {
    const db = await agentNotesDBPromise
    await db.delete('AgentNotes', chatId)
    console.log(`Agent notes for conversation ID "${chatId}" have been cleaned up.`)
  } catch (error) {
    console.error(`Failed to clean agent notes for conversation ID "${chatId}":`, error)
  }
}

export async function getConversationId(chatId: any) {
  return await apiClient.get(`${env_variables.CCP_CH_URL}/api/histories/conversation/${chatId}`, { headers: getAuthHeaders() })
}

export async function updateContactAttributes(payload: any) {
  if (!Object.keys(payload?.attributes).length) return false
  const updated = await apiClient
    .post(`${env_variables.CCP_API_END_POINT}/api/updateContactAttributes`, payload, { headers: getAuthHeaders() })
    .then(() => true)
    .catch((error: any) => {
      console.log('updateContactAttributes error: ', error)
      return false
    })
  return updated
}

export async function outboundMetadata(payload: any) {
  const updated = await apiClient
    .post(`${env_variables.CCP_API_END_POINT}/api/outbound/metadata`, payload, { headers: getAuthHeaders() })
    .then(() => true)
    .catch((error: any) => {
      console.log('outboundCallMetadata error: ', error)
      return false
    })
  return updated
}

export async function getConversationHistory(customerID: string) {
  const result = await apiClient.get(`${env_variables.CCP_CH_URL}/api/histories/customer?customerId=${customerID}`, { headers: getAuthHeaders() })
  return result
}

export async function getAllowedAgent(conversationId: string, agentId: string, linkedConversationId: string) {
  const result = await apiClient.get(
    `${env_variables.CCP_API_END_POINT}/api/v1/widget/allowedagent?conversationId=${conversationId}&agentId=${agentId}&linkedConversationId=${linkedConversationId}`,
    {
      headers: getAuthHeaders()
    }
  )
  return result
}

export async function stopRecording(payload: any) {
  const updated = await apiClient
    .post(`${env_variables.CCP_API_END_POINT}/api/record/stop`, payload, { headers: getAuthHeaders() })
    .then(() => true)
    .catch((error: any) => {
      console.log('Disable Recording Failure: ', error)
      return false
    })
  return updated
}

export async function getReleaseNotes() {
  try {
    const result = await apiClient.get(`${env_variables.CCP_API_END_POINT}/api/v1/ces/releaseNotes?active=true`, {
      headers: getAuthHeaders()
    })
    return result
  } catch (e) {
    return {}
  }
}

export async function getReleaseNotesPDF(version) {
  try {
    const result = await apiClient.get(`${env_variables.CCP_API_END_POINT}/api/v1/ces/releaseNotes/pdf?version=${version}`, {
      headers: getAuthHeaders(),
      responseType: 'blob'
    })
    return { success: true, result }
  } catch (e) {
    return { success: false }
  }
}
export async function updateVpnDisconnectedContactId(payload) {
  try {
    const result = await apiClient.put(`${env_variables.CCP_API_END_POINT}/api/v1/ces/vpndisconnect`, payload, { headers: getAuthHeaders() })
    return { success: true, result }
  } catch (e) {
    return { success: false }
  }
}

export async function getFeatureFlags(payload: any, url?: string) {
  const { agentId = '' } = payload
  try {
    const featureFlagsResponse = await apiClient.get(`${url ?? env_variables.FG_ENDPOINT_CONVO_SUMMARY}&_agentLdp=${agentId}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getFeatureFlags error: ', error)
    return {}
  }
}

export async function getFlagsForConversationSummary(queue: string = '') {
  try {
    const featureFlagsResponse = await apiClient.get(`${env_variables.FG_ENDPOINT_CONVO_SUMMARY}&_queue=${queue}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getFlagForConversationSummary error: ', error)
    return {}
  }
}

export async function getFlagsForRU(queue: string = '', agentLdap = '') {
  try {
    const featureFlagsResponse = await apiClient.get(`${env_variables.FG_ENDPOINT_RU}&_queue=${queue}&_agentLdp=${agentLdap}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getFlagForConversationSummary error: ', error)
    return {}
  }
}

export async function getFeatureFlagsForRTS(ldap: string = '') {
  try {
    const floodgateResponse = await apiClient.get(`${env_variables.FG_ENDPOINT_RTS}&clientId=RealTimeSentiment&_agentLdp=${ldap}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return floodgateResponse
  } catch (err) {
    console.log('getFeatureFlagsForRTS error: ', err)
    return {}
  }
}

export async function getTranslationData(reqData: any, headers: { Authorization: string; 'x-api-key': string; }) {
  try {
    const response = await apiClient.post(`${reqData.endpoint}/api/translate/chat`, reqData.body, { headers: headers });
    return response;
  } catch (e) {
    console.log('getTranslationData error: ', e)
  }
}

export async function fetchTapToolData() {
  try {
    const tapToolData = await apiClient.get(`${env_variables.CCP_API_END_POINT}/api/record/readFile`, { headers: getAuthHeaders() })
    return tapToolData?.data;
  } catch (err) {
    console.log("Error occured while calling Fetch Tap tool data API", err);
    throw err;
  }

}

export async function getOACFlags() {
  try {
    const featureFlagsResponse = await apiClient.get(`${env_variables.FG_ENDPOINT_OAC_FEATURE_FLAGS}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getOACFeatureFlags error: ', error)
    return {}
  }
}

export async function getFlagsForCaseNotes(queue: string = '', ldap: string = '') {
  try {
    const featureFlagsResponse = await apiClient.get(`${env_variables.FG_ENDPOINT_CASE_NOTES}&_queue=${queue}&_agentLdp=${ldap}`, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    })
    return featureFlagsResponse
  } catch (error) {
    console.log('getFlagForConversationSummary error: ', error)
    return {}
  }
}

async function getAgentNotes(conversationId: string): Promise<string> {
  try {
    const db = await agentNotesDBPromise
    const result = await db.get('AgentNotes', conversationId)
    return result?.notes.replace(/^\n+|\n+$/g, '').trim()   || ''
  } catch (error) {
    console.error(`Failed to fetch agent notes for conversation ID "${conversationId}":`, error)
    return ''
  }
}


export async function sendCaseNotes(conversation: any, agentDetails: any): Promise<any> {
  const {
    jcAuthData: {
      language: { value: queueLanguage = '' } = {},
      currentQueue: { value: currentQueue = '' } = {},
    } = {},
    ocPlatformData: {
      chatInfo: { currentContactId = '', initialContactId: conversationId = '' } = {},
      chatTranscript: transcript = [],
    } = {},
  } = conversation || {}

  const { ldap: agentLDAP = '', agentName = '' } = agentDetails || {}

  try {
    const agentNotes = await getAgentNotes(currentContactId)

    const caseNotesPayload = {
      conversationId,
      agentNotes,
      agentLDAP,
      agentName,
      currentQueue,
      currentContactId,
      queueLanguage,
      triggeredFromICH: false,
      transcript,
    }

    const headers = {
      'x-api-key': 'oac-custom-panel',
      'Authorization': localStorage.getItem('customPanelAccessToken')?.replace(/"/g, '') || '',
      'Content-Type': 'application/json',
    }
    const { data = {} } = await apiClient.post(
      `${env_variables.REACT_APP_CCM_BASE_URL}/api/v1/cases/notes`,
      caseNotesPayload,
      { headers }
    )
    console.log('Case notes sent successfully', data)
    return data
  } catch (error) {
    console.error('Failed to send case notes:', error)
    return {}
  } finally {
    await cleanAgentNotesDB(currentContactId)
  }
}