/* eslint-disable no-case-declarations */
import { CONVERSATION_CLOSED_STATUS, DEFAULT_TRIGGER_THRESHOLD_RTS } from '../../Apps/ContactCenter/Constants'
import {
  ContactRecord,
  Transcript,
  ConversationACData,
  initConversationACData,
  iBannerInfo,
  bannerInfoState,
  ToolBarCallData,
  iMessageMetaData,
  iTransferData
} from '../../Apps/ContactCenter/Models'
import Utility from '../../Apps/ContactCenter/Modules/Utility'

export interface State {
  CCPStatus: {
    isInit: boolean
    ccpState: string
    updatedBy: string
  }
  currentConversation: ContactRecord
  conversations: ContactRecord[]
  currentConversationAC: ContactRecord
  conversationsAC: ContactRecord[]
  conversationSummaries: {
    [contactId: string]: {
      feedback?: any
      summary: {
        main_issue?: string
        conversation_points?: Array<string>
        links?: Array<string>
      }
      path?: {
        ContactId: string
        DisplayName: string
        ParticipantId: string
      }[]
      final?: boolean
    }
  }
  previousLegInfo: {
    [contactId: string]: string[]
  }
  conversationLegSentiments: {
    [contactId: string]: string
  }
  conversationLegSentimentsLoading: {
    [contactId: string]: boolean
  }
  conversationLegSentimentsErrors: {
    [contactId: string]: string | undefined
  }
  contactRTSentimentErrors: {
    [contactId: string]: string | undefined
  }
  contactRTSentiments: {
    [contactId: string]: {
      sentiment: string
      alertState: string
      timeFirstNegative: number
      transcriptSentiment: Array<{
        sentiment: string
        timeStamp: number
      }>
    }
  }
  agentDetails: {
    agentName: string
    routingProfile: string
    ldap: string
    email: string
    skills: string[]
    states: string[]
    quickConnects: any
    agentSiteLocation: string
  }
  notification: {
    show: boolean
    type: string
    data: any
    newConv: any
  }
  newMessageIndicator: boolean
  alertData: {
    isOpen: boolean
    type: string
    msgText: string
  }
  currentView: string
  ACData: ConversationACData
  onTyping: {
    isTyping: boolean
    initialContactId: string
    participantRole: string
  }
  chatFileStatus: any
  autoTranslation: any
  transferInProgressId: string
  transferData: iTransferData
  incomingCallData: {
    message: any
    openPopup: string
  }
  onACW: boolean
  missedCallData: {
    message: any
    isMissed: string
  }
  recordingDisabled: {
    failed: boolean
    success: boolean
  }
  callConnecting: boolean
  newAgentConnecting: boolean
  participantLeft: boolean
  dilableCountries: string[]
  bannerInfo: iBannerInfo
  agentFeatureTags: any
  prevStatus: string
  isAuthFail: boolean
  toolBarCustomSelection: ToolBarCallData
  messageMetaData: iMessageMetaData
  showAdobeAnswers: boolean
  enableRTS: {
    [contactId: string]: boolean
  }
  enabledQueuesRTS: string[]
  timersRTS: {
    [contactId: string]: NodeJS.Timeout
  }
  adobeAnswersEligible: boolean
  showRUWidget: boolean
  isRUEligible: boolean
  caseNotesEligible: boolean
  enableMockOfferId: boolean
  toggleTranslationforCS: boolean
  triggerThresholdRTS: number
  bargedContactIds: {
    [contactId: string] : {
      contactId?: string
      isBarged?: boolean
    }
  }
}
export const contactCenterReducer = (
  state: State = {
    CCPStatus: { isInit: false, ccpState: '', updatedBy: '' },
    currentConversation: {
      ocPlatformData: {
        chatInfo: {
          initialContactId: '',
          currentContactId: '',
          status: ''
        },
        callInfo: {},
        chatTranscript: []
      }
    },
    conversations: [],
    currentConversationAC: {
      ocPlatformData: {
        chatInfo: {
          initialContactId: '',
          currentContactId: '',
          status: ''
        },
        callInfo: {
          initialContactId: '',
          currentContactId: '',
          status: ''
        },
        chatTranscript: []
      }
    },
    conversationsAC: [],
    conversationSummaries: {},
    conversationLegSentiments: {},
    conversationLegSentimentsLoading: {},
    conversationLegSentimentsErrors: {},
    contactRTSentimentErrors: {},
    contactRTSentiments: {},
    previousLegInfo: {},
    agentDetails: {
      agentName: '',
      routingProfile: '',
      ldap: '',
      email: '',
      skills: [],
      states: [],
      quickConnects: [],
      agentSiteLocation: ''
    },
    notification: {
      show: false,
      type: '',
      data: '',
      newConv: {}
    },
    newMessageIndicator: false,
    alertData: {
      isOpen: false,
      type: '',
      msgText: ''
    },
    currentView: '',
    ACData: initConversationACData(),
    onTyping: {
      isTyping: false,
      initialContactId: '',
      participantRole: ''
    },
    chatFileStatus: {},
    autoTranslation: {},
    transferInProgressId: '',
    transferData: {},
    incomingCallData: {
      message: {},
      openPopup: ''
    },
    onACW: false,
    missedCallData: {
      message: {},
      isMissed: ''
    },
    recordingDisabled: {
      failed: false,
      success: false
    },
    callConnecting: false,
    newAgentConnecting: false,
    participantLeft: false,
    dilableCountries: [],
    bannerInfo: bannerInfoState(),
    agentFeatureTags: {},
    prevStatus: '',
    isAuthFail: false,
    toolBarCustomSelection: {
      countryCode: '',
      customerId: '',
      callStartTime: '',
      origin: '',
      queueARN: '',
      queueName: '',
      status: ''
    },
    messageMetaData: {},
    showAdobeAnswers: false,
    enableRTS: {},
    enabledQueuesRTS: [],
    timersRTS: {},
    adobeAnswersEligible: false,
    showRUWidget: false,
    isRUEligible: false,
    caseNotesEligible: false,
    enableMockOfferId: false,
    toggleTranslationforCS: false,
    triggerThresholdRTS: DEFAULT_TRIGGER_THRESHOLD_RTS,
    bargedContactIds: {}
  },
  action: any
): State => {
  const summariesCopy = { ...state.conversationSummaries }
  switch (action.type) {
    case 'INITCCP':
      return { ...state, CCPStatus: { ...state.CCPStatus, isInit: true } }
    case 'SETCCPSTATE':
      return { ...state, CCPStatus: { ...state.CCPStatus, ccpState: action.payload, updatedBy: action.updatedBy } }
    case 'SETCURRENTCONVERSATION':
      const currentConv = action.payload
      const conversations = [...state.conversations]
      const currentIndex = conversations.findIndex((x: ContactRecord) => x.ocPlatformData.chatInfo.currentContactId === currentConv.ocPlatformData.chatInfo.currentContactId)
      conversations[currentIndex] = currentConv
      return { ...state, currentConversation: currentConv || {}, conversations }
    case 'SETCONVERSATIONS':
      let currentConv1 = { ...state.currentConversation }
      if (currentConv1 && currentConv1.ocPlatformData && currentConv1.ocPlatformData.chatInfo && currentConv1.ocPlatformData.chatInfo.currentContactId)
        currentConv1 = action.payload.find((x: ContactRecord) => x.ocPlatformData.chatInfo.currentContactId === currentConv1.ocPlatformData.chatInfo.currentContactId)
      return { ...state, conversations: action.payload, currentConversation: currentConv1 }
    case 'SETCONVERSATIONSUMMARY':
      const { contactId: contactIdSum, summary, path, final = undefined } = action.payload
      summariesCopy[contactIdSum] = { summary, path, feedback: undefined, final }
      return { ...state, conversationSummaries: summariesCopy }
    case 'SET_PREVIOUS_LEG_INFO_FOR_CURRENT_CONTACT':
      const {contactId: currentLegContactId, previousLegInfo } = action.payload
      const previousLegInfoCopy = { ...state.previousLegInfo }
      previousLegInfoCopy[currentLegContactId] =  previousLegInfo 
      return { ...state, previousLegInfo: previousLegInfoCopy }
    case 'CONVERSATION_SUMMARY_FEEDBACK':
      const { contactId: contactIdUpdateFeedBack, feedback, summary: conversationSummary, path: agentPath } = action.payload
      const summaryCopy = { feedback, summary: conversationSummary, path: agentPath }
      summariesCopy[contactIdUpdateFeedBack] = summaryCopy
      return { ...state, conversationSummaries: summariesCopy }

    case 'SET_SHOW_ADOBE_ANSWERS':
      return { ...state, showAdobeAnswers: action.payload }

    case 'SET_ADOBE_ANSWERS_ELIGIBLE':
      return { ...state, adobeAnswersEligible: action.payload }

    case 'SET_SHOW_RU_WIDGET':
      return { ...state, showRUWidget: action.payload }

    case 'SET_RU_ELIGIBLE':
      return { ...state, isRUEligible: action.payload }

    case 'SET_CASE_NOTES_ELIGIBLE':
      return { ...state, caseNotesEligible: action.payload }
    
    case 'SET_ENABLE_MOCK_OFFER_ID':
    return { ...state, enableMockOfferId: action.payload }

    case 'SETCONVERSATIONSENTIMENTS':
      const sentiments = action.payload
      const newSentimentsState = { ...state.conversationLegSentiments }
      for (let contactId of Object.keys(sentiments)) {
        const sentiment = sentiments[contactId]
        newSentimentsState[contactId] = sentiment
      }
      return { ...state, conversationLegSentiments: newSentimentsState }
    case 'SETCONVERSATIONSENTIMENTLOADING':
      return {
        ...state,
        conversationLegSentimentsLoading: {
          ...state.conversationLegSentimentsLoading,
          [action.payload?.contactId]: action.payload?.loading
        }
      }
    case 'SETCONVERSATIONSENTIMENTERRORS':
      return {
        ...state,
        conversationLegSentimentsErrors: {
          ...state.conversationLegSentimentsErrors,
          [action.payload?.contactId]: action.payload?.error
        }
      }
    case 'SET_ENABLE_RTS':
      return {
        ...state,
        enableRTS: {
          ...state.enableRTS,
          [action.payload?.currentContactId]: action.payload?.enabled
        }
      }
    case 'SET_RTS_ENABLED_QUEUES':
      return {
        ...state,
        enabledQueuesRTS: action.payload 
      }
    case 'SET_RTS_TIMERS':
      if (state.timersRTS[action.payload?.contactId]) {
        clearInterval(state.timersRTS[action.payload.contactId])
      }
      return {
        ...state,
        timersRTS: {
          ...state.timersRTS,
          [action.payload?.contactId]: action.payload?.intervalId
        }
      }
    case 'REMOVE_RTS_TIMER':
      return {
        ...state,
        timersRTS: {
          ...state.timersRTS,
          [action.payload]: undefined
        }
      }
    case 'SET_RT_SENTIMENT_ERRORS':
      return {
        ...state,
        contactRTSentimentErrors: {
          ...state.contactRTSentimentErrors,
          [action.payload?.contactId]: action.payload?.error
        }
      }
    case 'SET_CONTACT_RTS_SENTIMENT':
      return {
        ...state,
        contactRTSentiments: {
          ...state.contactRTSentiments,
          [action.payload?.contactId]: action.payload?.sentiment
        }
      }
    case 'SET_RTS_TRIGGER_THRESHOLD':
      return {
        ...state,
        triggerThresholdRTS: action.payload
      }

    case 'ADDMESSAGE':
      let updConvs = [...state.conversations]
      updConvs = updConvs.map((a) => {
        const returnValue = { ...a }
        const newMessage: Transcript = action.payload
        const agentMessageType = ['MESSAGE', 'ATTACHMENT']
        if (
          a.ocPlatformData.chatInfo.currentContactId === newMessage.ContactId &&
          a.ocPlatformData.chatTranscript &&
          a.ocPlatformData.chatTranscript.findIndex((t) => t.Id === newMessage.Id) < 0
        ) {
          if (
            (newMessage.ParticipantRole === 'AGENT' && agentMessageType.includes(newMessage.Type)) ||
            (a.ocPlatformData.chatTranscript[a.ocPlatformData.chatTranscript.length - 1].ParticipantRole === 'AGENT' &&
              agentMessageType.includes(a.ocPlatformData.chatTranscript[a.ocPlatformData.chatTranscript.length - 1].Type) &&
              newMessage.ParticipantRole === 'CUSTOMER')
          )
            returnValue.ocPlatformData.chatInfo.timer = 180
          let newMessageStatus =
            (a.ocPlatformData.chatInfo.status === 'Overdue' && newMessage.ParticipantRole === 'CUSTOMER') || newMessage.ParticipantRole === 'SYSTEM' || newMessage.Type === 'EVENT'
              ? a.ocPlatformData.chatInfo.status
              : 'Active'
          if (action.payload?.isChatClosed && a.ocPlatformData.chatInfo.currentContactId === newMessage.ContactId) {
            newMessageStatus = CONVERSATION_CLOSED_STATUS
            delete action.payload.isChatClosed
          }
          returnValue.ocPlatformData.chatInfo.status = newMessageStatus
          if (action.payload.translationText) {
            const newMessageTr = { ...action.payload }
            newMessageTr.Content = action.payload.translationText
            delete action.payload.translationText
            returnValue.ocPlatformData.translationTranscript = [...(returnValue.ocPlatformData.translationTranscript || []), newMessageTr]
          }
          returnValue.ocPlatformData.chatTranscript = [...(returnValue.ocPlatformData.chatTranscript || []), action.payload]
        }
        return returnValue
      })
      let currentConv2: any = { ...state.currentConversation }
      if (currentConv2 && currentConv2.ocPlatformData && currentConv2.ocPlatformData.chatInfo && currentConv2.ocPlatformData.chatInfo.currentContactId)
        currentConv2 = updConvs.find((x) => x.ocPlatformData.chatInfo.currentContactId === currentConv2.ocPlatformData.chatInfo.currentContactId)

      return { ...state, conversations: updConvs, currentConversation: currentConv2 || {} }

    case 'SETAGENTDETAILS':
      return { ...state, agentDetails: { ...state.agentDetails, ...action.payload } }
    case 'SETNOTIFICATION':
      return { ...state, notification: action.payload }
    case 'SETNEWMESSAGEINDICATOR':
      return { ...state, newMessageIndicator: action.payload }
    case 'SETOFFLINEINDICATOR':
      return { ...state, alertData: action.payload }
    case 'SETCURRENT_AC':
      const conversationsAC = [...state.conversationsAC]
      if (action.payload?.ocPlatformData?.chatInfo?.currentContactId) {
        const currIndex = conversationsAC.findIndex((x: ContactRecord) => x.ocPlatformData.chatInfo.currentContactId === action.payload.ocPlatformData.chatInfo.currentContactId)
        conversationsAC[currIndex] = action.payload
        return { ...state, currentConversationAC: action.payload, conversationsAC }
      }
      return { ...state, currentConversationAC: action.payload }
    case 'SETALL_AC':
      return { ...state, conversationsAC: action.payload }
    case 'SET_CURRENT_VIEW':
      return { ...state, currentView: action.payload }
    case 'SET_AC_DATA':
      return { ...state, ACData: action.payload }
    case 'SAVE_DRAFT':
      const convsForDraftMsg = [...state.conversations]
      const prevCurrentConvIndex = convsForDraftMsg.findIndex((x: ContactRecord) => x.ocPlatformData.chatInfo.currentContactId === action.payload.prevCurrentContactId)
      if (prevCurrentConvIndex > -1) {
        const { messageDraft = '', translationDraft = '' } = action.payload
        convsForDraftMsg[prevCurrentConvIndex].ocPlatformData.messageDraft = messageDraft
        convsForDraftMsg[prevCurrentConvIndex].ocPlatformData.translationDraft = translationDraft
      }
      return { ...state, conversations: convsForDraftMsg }
    case 'SET_ON_TYPING':
      return { ...state, onTyping: action.payload }
    case 'SET_ON_UPLOADING':
      let newChatFileStatus = { ...state.chatFileStatus }
      newChatFileStatus[action.payload.contactId] = {
        contactId: action.payload.contactId,
        uploading: action.payload.uploading,
        apiUploadError: action.payload.apiUploadError
      }
      return { ...state, chatFileStatus: newChatFileStatus }
    case 'SET_TRANSFER_IN_PROGRESS_ID':
      return { ...state, transferInProgressId: action.payload }
    case 'SET_TRANFER_SELECTION':
      let updateTransferData = { ...state.transferData }

      if (!updateTransferData[action.payload.contactId]) {
        updateTransferData[action.payload.contactId] = { [action.payload.transferType]: action.payload.transferData }
      } else {
        const transferDataForTranferType = { ...updateTransferData[action.payload.contactId] }
        transferDataForTranferType[action.payload.transferType] = action.payload.transferData
        updateTransferData[action.payload.contactId] = transferDataForTranferType
      }

      return { ...state, transferData: updateTransferData }

    case 'END_CALL':
      const callLog = [...state.conversations]
      const findActiveCall = callLog?.findIndex((s: any) => s.ocPlatformData.callInfo?.status === 'Active')
      const { ocPlatformData: { callInfo: { origin = '', currentContactId = '', linkedConversationId = '' } = {} } = {} } = callLog[findActiveCall] || {}
      const contactId = origin === 'chat-to-phone' ? linkedConversationId : currentContactId
      const timerInfo = Utility.getActiveTimerInfo()
      const activeTimerInfo = timerInfo[contactId] || {}
      const voiceAgentEndTime = activeTimerInfo?.voiceAgentEndTime || new Date()
      const voiceAgentStartTime = activeTimerInfo?.voiceAgentStartTime || new Date()
      if (findActiveCall >= 0) {
        callLog[findActiveCall].ocPlatformData.callInfo.status = 'Closed'
        callLog[findActiveCall].ocPlatformData.callInfo.assignedAgent = false
        if (callLog[findActiveCall].ocPlatformData.callInfo.origin !== 'chat-to-phone' && voiceAgentEndTime) {
          callLog[findActiveCall].ocPlatformData.chatInfo.duration = Math.round((new Date(voiceAgentEndTime).getTime() - new Date(voiceAgentStartTime).getTime()) / 1000) || 0
          callLog[findActiveCall].ocPlatformData.chatInfo.status = 'Closed'
        }
        if (callLog[findActiveCall].ocPlatformData.callInfo.origin === 'chat-to-phone' && !callLog[findActiveCall].jcAuthData?.channel) {
          callLog[findActiveCall].ocPlatformData.chatInfo.status = 'Closed'
        }
        callLog[findActiveCall].ocPlatformData.chatInfo.updateDate = voiceAgentEndTime
      }
      return { ...state, conversations: callLog }
    case 'INCOMING_CALL':
      return { ...state, incomingCallData: action.payload }

    case 'SET_ACW': {
      const bannerInfo = { ...state.bannerInfo }
      bannerInfo.isAcw = action.payload || false
      if (!bannerInfo.isAcw) {
        bannerInfo.callConnections = []
        bannerInfo.isRinging = false
        bannerInfo.contactId = ''
        bannerInfo.queueNames = {}
        bannerInfo.queueName = ''
      }
      return { ...state, bannerInfo: bannerInfo }
    }

    case 'SET_AUTO_TRANSLATION':
      let newAutoTranslationData = { ...state.autoTranslation }
      const oldUpsertTrMsg = newAutoTranslationData[action.payload.contactId]?.upsertTrMsg || {}
      const newUpsertTrMsg = action.payload.upsertTrMsg || {}
      newAutoTranslationData[action.payload.contactId] = {
        contactId: action.payload.contactId || '',
        isInit: action.payload.isInit || false,
        translation: action.payload.translation || false,
        isLoading: action.payload.isLoading || false,
        lastTranslateId: action.payload.lastTranslateId || '',
        isCustomerTranslatedMsg: action.payload.isCustomerTranslatedMsg || false,
        upsertTrMsg: action.payload.translation ? { ...oldUpsertTrMsg, ...newUpsertTrMsg } : {}
      }
      return { ...state, autoTranslation: newAutoTranslationData }

    case 'MISSED_CALL':
      return { ...state, missedCallData: action.payload }

    case 'RECORDING_DISABLED':
      return { ...state, recordingDisabled: action.payload }

    case 'PARTICIPANT_LEFT_THE_CALL':
      return { ...state, participantLeft: action.payload }

    case 'GET_COUNTRIES':
      return { ...state, dilableCountries: action.payload }

    case 'SET_BANNER_INFO':
      return { ...state, bannerInfo: action.payload }

    case 'SET_OUTBOUND_CALL': {
      const bannerInfo = { ...state.bannerInfo }
      bannerInfo.outBoundCall = action.payload || false
      return { ...state, bannerInfo: bannerInfo }
    }

    case 'SET_AGENT_FEATURE_TAGS':
      return { ...state, agentFeatureTags: action.payload }

    case 'SET_PREV_STATUS':
      return { ...state, prevStatus: action.payload }

    case 'SET_AUTH_FAIL':
      return { ...state, isAuthFail: action.payload }

    case 'SET_TOOLBAR_CUSTOM_SELECTION':
      return { ...state, toolBarCustomSelection: action.payload }

    case 'SET_MESSAGE_META_DATA':
      let cloneMessageMetaData = { ...state.messageMetaData }
      const oldMessageData = cloneMessageMetaData[action.payload.contactId] || {}
      cloneMessageMetaData[action.payload.contactId] = { ...oldMessageData, ...action.payload }
      return { ...state, messageMetaData: cloneMessageMetaData }

    case 'TOGGLE_TRANSLATION_CS': {
      return { ...state, toggleTranslationforCS: action.payload }
    }
    
    case "SET_IS_BARGED": 
    let bargedValue = { ...state.bargedContactIds};
    const oldbargedContactIdValue = bargedValue[action.payload.contactId] || {};
    bargedValue[action.payload.contactId] = { ...oldbargedContactIdValue, ...action.payload };
    return { ...state, bargedContactIds: bargedValue};

    default:
      return state
  }
}
