/* eslint-disable import/no-cycle */
import { ContactRecord, Transcript, ConversationACData } from '../../Apps/ContactCenter/Models'
import { SET_AGENT_DETAILS } from '../constants/preferencesConstants'
import ContactCenter from '../../Apps/ContactCenter/Modules/ContactCenter'
import { updateConversationSummaryFeedbackAPI, getClosedConversationSummaryAPI } from '../../Apps/ContactCenter/Api/transcripts'
// import { enableConvSummaryByQueue } from '../../utils/fg-utils'
import { Dispatch } from 'redux'

export const initCCP = () => (dispatch: any) => {
  dispatch({ type: 'INITCCP', payload: true })
}

export const setCurrentStatus = (newState: string, updatedBy: string) => (dispatch: any) => {
  console.log(`CCP Status set dispatch is triggered with new status : ${newState}`)
  dispatch({ type: 'SETCCPSTATE', payload: newState, updatedBy })
}

export const setCurrentConversation = (conversation: ContactRecord) => async (dispatch: any) => {
  const transcript = conversation.ocPlatformData.chatTranscript
  if (!transcript || transcript.length === 0) {
    const res = await ContactCenter.getTranscript(conversation.ocPlatformData.chatInfo.initialContactId)
    conversation.ocPlatformData.chatTranscript = res?.data || []
  }
  const newCus = conversation.ocPlatformData.chatInfo.newCustomer
  if (typeof newCus == 'undefined') {
    conversation.ocPlatformData.chatInfo.newCustomer = await ContactCenter.isNewCustomer(conversation.jcAuthData?.customerId?.value)
  }
  dispatch({ type: 'SETCURRENTCONVERSATION', payload: conversation })
}

export const setConversations = (conversations: ContactRecord[]) => (dispatch: any) => {
  console.trace('Tracing set conversations')
  dispatch({ type: 'SETCONVERSATIONS', payload: conversations })
}

export const addMessage = (message: Transcript) => (dispatch: any) => {
  dispatch({ type: 'ADDMESSAGE', payload: message })
}

export const setAgentDetails = (agentDetails: any) => (dispatch: any) => {
  dispatch({ type: 'SETAGENTDETAILS', payload: agentDetails })
  dispatch({ type: SET_AGENT_DETAILS, payload: agentDetails })
}

export const setCurrentAC = (currentConversationAC: ContactRecord) => async (dispatch: any) => {
  const transcript = currentConversationAC?.ocPlatformData?.chatTranscript || []
  if ((!transcript || transcript.length === 0) && currentConversationAC?.ocPlatformData?.chatInfo?.currentContactId) {
    const resp = await ContactCenter.getTranscript(currentConversationAC.ocPlatformData.chatInfo.initialContactId)
    currentConversationAC.ocPlatformData.chatTranscript = resp?.data || []
  }
  dispatch({ type: 'SETCURRENT_AC', payload: currentConversationAC })
}

export const setAllAC = (conversationsAC: ContactRecord[]) => (dispatch: any) => {
  dispatch({ type: 'SETALL_AC', payload: conversationsAC })
}

export const setCurrentView = (currentView: string) => (dispatch: any) => {
  dispatch({ type: 'SET_CURRENT_VIEW', payload: currentView })
}

export const setACData = (ACData: ConversationACData) => (dispatch: any) => {
  dispatch({ type: 'SET_AC_DATA', payload: ACData })
}

export const saveDraft = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SAVE_DRAFT', payload: data })
}

export const setOnTyping = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_ON_TYPING', payload: data })
}

export const setUpload = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_ON_UPLOADING', payload: data })
}

export const endCall = () => (dispatch: any) => {
  dispatch({ type: 'END_CALL' })
}

export const IncomingCall = (data: any) => (dispatch: any) => {
  dispatch({ type: 'INCOMING_CALL', payload: data })
}

export const setAutoTranslation = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_AUTO_TRANSLATION', payload: data })
}

export const setConversationSummary = (data: { contactId: any; summary: any; final: boolean }) => (dispatch: any) => {
  dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: data })
}

export const setConversationSentiments = (data: { [contactId: string]: string }) => (dispatch: Dispatch) => {
  dispatch({ type: 'SETCONVERSATIONSENTIMENTS', payload: data })
}

export const setContactRealTimeSentiment =
  (data: {
    contactId: string
    sentiment: {
      sentiment: string
      alertState: string
      timeFirstNegative: number
      transcriptSentiment: Array<{
        sentiment: string
        timeStamp: number
      }>
    }
  }) =>
  (dispatch: Dispatch) => {
    const dataFromBackend = {
      ...data,
      sentiment: {
        ...data?.sentiment,
        alertState: (data?.sentiment?.alertState ?? '').toLowerCase()
      }
    }
    console.log('##rts setContactRealTimeSentiment dataFromBackend', dataFromBackend)
    dispatch({ type: 'SET_CONTACT_RTS_SENTIMENT', payload: dataFromBackend })
  }

export const updateConversationSummaryFeedback = (data: { contactId: any; feedback: any; summary: any; path: any[] }) => async (dispatch: any) => {
  const { contactId, feedback, summary, path } = data
  const params = {
    SequenceId: summary?.SequenceId,
    SummaryUseful: feedback ? 1 : 0,
    AgentFeedbackCategory: path.map(({ DisplayName }) => DisplayName).join(' -> ')
  }
  const response = await updateConversationSummaryFeedbackAPI(params, contactId)
  if (response) {
    dispatch({ type: 'CONVERSATION_SUMMARY_FEEDBACK', payload: { contactId, feedback, summary, path } })
  }
}

export const setBannerInfo = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_BANNER_INFO', payload: data })
}

export const setOutBoundCall = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_OUTBOUND_CALL', payload: data })
}

export const setAcw = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_ACW', payload: data })
}

export const setAgentFeatureTags = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_AGENT_FEATURE_TAGS', payload: data })
}

export const setAuthFail = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_AUTH_FAIL', payload: data })
}

export const setMessageMetaData = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_MESSAGE_META_DATA', payload: data })
}

export const getClosedConversationSummary = (conversationId: any, queue: any, transcript: any[]) => async (dispatch: any) => {
  if (!conversationId) return
  // let isEnabledByQueue = false
  const nodata = {
    contactId: conversationId,
    feedback: undefined,
    ldap: undefined,
    path: 'No data',
    summary: {
      main_issue: 'Summary is not available for this conversation',
      conversation_points: [],
      links: []
    }
  }
  // Commenting this check since Conv Summary is enabled for all queues
  // try {
  //   isEnabledByQueue = await enableConvSummaryByQueue(queue)
  // } catch (e) {
  //   console.error('Error with checking summary eligibility, Error: ', e)
  // }
  // if (!isEnabledByQueue) {
  //   return
  // }
  const params = { conversationId, transcript }
  const loadingPath = 'Loading data...'
  const loadingSummary = {
    main_issue: 'Loading data...',
    conversation_points: ['Loading data...'],
    links: ['Loading data...']
  }
  try {
    dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: { contactId: conversationId, feedback: undefined, ldap: undefined, summary: loadingSummary, path: loadingPath } })
    const result = await getClosedConversationSummaryAPI(params)
    const { Summary: summary } = result

    const transferCategoryPath = result?.TransferCategory?.split('->')?.map((path) => path.trim()) ?? [] // ['BOT', 'Agent1', 'Agent2'] need to trim() because sometimes there are spaces in the path. refer to https://git.corp.adobe.com/ces-omnichannel/oc-rtcs-processor-api/blob/main/src/services/genAI/generateGenAIResponse.ts#L43
    let summaryPath = result?.path ?? []
    if (result?.final && transferCategoryPath.length !== 0) {
      summaryPath = transferCategoryPath.map((path) => ({ DisplayName: path }))
    } else if (transferCategoryPath.length !== 0) {
      // if TransferCategory from the conversationSummary is empty there is something wrong with the summary. In this case we show the full path which may not be correct but this is preferable over showing no path at all: CSME-84300
      summaryPath = result?.path?.slice(0, transferCategoryPath.length) || []
    }
    //if final === true but in the table only 1 summary -> that is mean
    //that final summary was not generated and reaponse will contain empty Summary: {}
    if (summary && Object.keys(summary).length > 0) {
      dispatch({
        type: 'SETCONVERSATIONSUMMARY',
        payload: {
          contactId: conversationId,
          feedback: result?.SummaryUseful,
          ldap: result?.AgentLdap,
          summary: summary,
          path: summaryPath,
          final: result?.final
        }
      })
    } else {
      //in case if final summary was not generated yet, will show empty summary
      dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: nodata })
    }
  } catch (e) {
    console.error('Error for fetching existing conversation summaries getClosedConversationSummary, Error: ', e)
    dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: nodata })
  }
}

export const toggleTranslationforCS = (data: any) => (dispatch: any) => {
  dispatch({ type: 'TOGGLE_TRANSLATION_FOR_CS', payload: data })
}

export const setIsBarged = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_IS_BARGED', payload: data })
}
