export interface defaultStateInterface {
  miloUpdatedInfo: {
    [contactId: string]: {
      updatedToMilo: boolean
    }
  }
}

const defaultState: defaultStateInterface = {
  miloUpdatedInfo: {}
}

const caseNotesReducer = (state: defaultStateInterface = defaultState, action: any) => {
  const miloUpdatedInfoCopy = { ...state.miloUpdatedInfo }
  //console.log('caseNotesReducer', action, state)
  switch (action.type) {
    case 'SET_MILO_UPDATED':
      const { contactId, updatedToMilo } = action.payload
      miloUpdatedInfoCopy[contactId] = { updatedToMilo }
      return { miloUpdatedInfo: miloUpdatedInfoCopy }
    default:
      return state
  }
}

export default caseNotesReducer
