/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux'
import config from '../../../config/env_variables'

interface State {
  contactCenter: {
    currentConversation: {
      rengaID: string
      conversationID: string
    }
  }
  preferences: {
    darkMode: boolean
    language: string
  }
}

const useContextData = () => {
  const { currentConversation, currentConversationAC, currentView, agentDetails = {} } = useSelector((state: any) => state.contactCenter)
  const contactCenterData = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation
  const changeCustomerData = useSelector((state: any) => state.changeCustomer)

  console.log(
    'useContextData data from store',
    useSelector((state: any) => state.contactCenter)
  )
  console.log('Original JCAuthData for QE validation', contactCenterData)
  const {
    selectedCustomer: { rengaId: ccRengId = '', firstName: ccFirstName = '', lastName: ccLastName = '', email: ccEmail = '', contactId: ccContactId = '', phone: ccPhone = '' } = {}
  } = changeCustomerData

  const {
    jcAuthData: {
      accountType: { value: accountType = '' } = {},
      agentId: { value: agentId = '' } = {},
      aspSupportData: { value: aspSupportData = '' } = {},
      aspSupportRegion: { value: aspSupportRegion = '' } = {},
      botAuthUserInputData: { value: botAuthUserInputDataStr = '' } = {},
      customerId: { value: rengaId = '' } = {},
      customerAuthId: { value: customerAuthId = '' } = {},
      customerType: { value: customerType = '' } = {},
      country: { value: country = '' } = {},
      countryCode: { value: countryCode = '' } = {},
      compamy: { value: company = '' } = {},
      firstName: { value: firstName = '' } = {},
      lastName: { value: lastName = '' } = {},
      email: { value: customerEmail = '' } = {},
      feedbackScreenName: { value: feedbackScreenName = '' } = {},
      feedbackComment: { value: feedbackComment = '' } = {},
      feedbackValue: { value: feedbackValue = '' } = {},
      entitlementsList: { value: entitlementsList = '' } = {},
      deviceFamily: { value: deviceFamily = '' } = {},
      deviceManufacture: { value: deviceManufacture = '' } = {},
      disconnectReason: { value: disconnectReason = '' } = {},
      os: { value: os = '' } = {},
      osVersion: { value: osVersion = '' } = {},
      osName: { value: osName = '' } = {},
      origin: { value: origin = '' } = {},
      HostContextInfo: { value: HostContextInfo = '' } = {},
      ipAddress: { value: ipAddress = '' } = {},
      isMultipleAccountLinked: { value: isMultipleAccountLinked = '' } = {},
      browserVersion: { value: browserVersion = '' } = {},
      browser: { value: browser = '' } = {},
      botFlow: { value: botFlow = '' } = {},
      botProductName: { value: botProductName = '' } = {},
      botProductCode: { value: botProductCode = '' } = {},
      phone: { value: phone = '' } = {},
      phoneNumber: { value: phoneNumber = '' } = {},
      productCode: { value: productCode = '' } = {},
      prechatIssue: { value: prechatIssue = '' } = {},
      prechatCustomerType: { value: prechatCustomerType = '' } = {},
      prechatConnectUrl: { value: prechatConnectUrl = '' } = {},
      surfaceAppId: { value: surfaceAppId = '' } = {},
      surfaceIpRegion: { value: surfaceIpRegion = '' } = {},
      surfaceRegion: { value: surfaceRegion = '' } = {},
      surfaceUrl: { value: surfaceUrl = '' } = {},
      surfaceAppVersion: { value: surfaceAppVersion = '' } = {},
      subProductName: { value: subProductName = '' } = {},
      subProductCode: { value: subProductCode = '' } = {},
      subissueTypeCode: { value: subissueTypeCode = '' } = {},
      queueName: { value: queueName = '' } = {},
      orgId: { value: orgId = '' } = {},
      vipRouting: { value: vipRouting = '' } = {},
      language: { value: language = '' } = {},
      languageCode: { value: languageCode = '' } = {},
      linkedConversationId: { value: linkedConversationId = '' } = {},
      region: { value: region = '' } = {},
      returningCustomerCaseId: { value: returningCustomerCaseId = '' } = {},
      returningCustomerType: { value: returningCustomerType = '' } = {},
      issueType: { value: issueType = '' } = {},
      issueTypeCode: { value: issueTypeCode = '' } = {},
      timezone: { value: timezone = '' } = {},
      transferCount: { value: transferCount = '' } = {},
      handoffCount: { value: handoffCount = '' } = {},
      chatTransferCount: { value: chatTransferCount = '' } = {},
      subissueType: { value: subissueType = '' } = {},
      channel: { value: channel = '' } = {},
      botIntent: { value: botIntent = '' } = {},
      botSubintent: { value: botSubintent = '' } = {},
      currentQueue: { value: currentQueue = '' } = {},
      queuePath: { value: queuePath = '' } = {},
      inQueuePromptInterest: { value: inQueuePromptInterest = '' } = {},
    } = {},
    ocPlatformData: { chatInfo: { initialContactId = '', currentContactId = '', chatStartTime = '', status = '' } = {}, chatTranscript = [] } = {}
  } = contactCenterData
  const { agentName = '', email: agentEmail = '', ldap = '', agentSiteLocation = '' } = agentDetails
  const { darkMode, language: globalLanguage } = useSelector((state: State) => state.preferences)
  const updatedToMilo = useSelector((state: any) => state.caseNotes.miloUpdatedInfo[currentContactId]?.updatedToMilo) || false

  const getChatTranscript = (transcripts: any) => {
    if (transcripts && transcripts.length > 0) {
      return transcripts.map((transcript: any) => {
        const { AbsoluteTime: timeStamp = '', Content: message = '', DisplayName: senderName = '', ParticipantRole: sentBy = '' } = transcript
        return {
          timeStamp,
          message,
          senderName,
          sentBy
        }
      })
    }
    return []
  }

  let closedPhoneCall = true
  let closedConversation = true
  let currentConvPhoneDetails = {}
  let originForCurrentConversation = 'inbound-chat'

  const getCallDataForCurrentConversation = () => {
    const { ocPlatformData: { callInfo = {} } = {} } = contactCenterData
    if (callInfo && Object.keys(callInfo).length > 0) {
      const { status = '', origin } = callInfo
      if (status === 'Active') closedPhoneCall = false
      originForCurrentConversation = origin
      currentConvPhoneDetails = callInfo
    }
  }

  getCallDataForCurrentConversation()

  if (status && status.toLowerCase() !== 'closed') {
    closedConversation = false
  }
  const botAuthUserInputData: any = botAuthUserInputDataStr ? JSON.parse(botAuthUserInputDataStr)?.email : ''
  const json = {
    jcAuthData: {
      surfaceAppId,
      transferCount,
      handoffCount,
      chatTransferCount,
      agentEmail,
      aspSupportData,
      aspSupportRegion,
      botAuthUserInputData,
      surfaceAppVersion,
      surfaceUrl,
      surfaceRegion,
      surfaceIpRegion,
      deviceFamily,
      os,
      osVersion,
      osName,
      browser,
      browserVersion,
      deviceManufacture,
      ipAddress,
      firstName,
      lastName,
      linkedConversationId,
      email: customerEmail,
      country,
      countryCode,
      phone,
      phoneNumber,
      company,
      customerId: rengaId,
      accountType,
      customerAuthId,
      queueName,
      customerType,
      orgId,
      vipRouting,
      language,
      languageCode,
      region,
      timezone,
      issueType,
      issueTypeCode,
      isMultipleAccountLinked,
      subissueType,
      subissueTypeCode,
      entitlementsList,
      productCode,
      subProductName,
      subProductCode,
      botFlow,
      returningCustomerType,
      returningCustomerCaseId,
      channel,
      prechatConnectUrl,
      prechatIssue,
      prechatCustomerType,
      currentQueue,
      queuePath,
      inQueuePromptInterest
    },
    jcMidConvData: {
      feedbackScreenName,
      feedbackValue,
      feedbackComment,
      HostContextInfo,
      botIntent,
      botSubintent,
      botProductCode,
      botProductName,
      disconnectReason,
      'custom_<key>': '<custom_value>'
    },
    ocPlatformData: {
      chatInfo: {
        contactId: initialContactId,
        currentContactId,
        contactIds: ['Successive Contact Ids when Transfer Occurs'],
        chatStartTime,
        queueName,
        status
      },
      callInfo: { ...currentConvPhoneDetails },
      // Temporary fix for the issue of chatting agent is not returned in the chat transcript
      chattingAgentInfo: {
        agentID: agentEmail,
        agentName,
        agentLDAP: ldap
      },
      agentInfo: {
        agentID: agentEmail,
        agentName,
        agentLDAP: ldap,
        agentSiteLocation
      },
      chatTranscript: getChatTranscript(chatTranscript),
      transfers: [
        {
          timeStamp: '2019-10-10 10:52:56.407+0000',
          targetQueueName: 'CCT-Eng-Admin-Msg',
          sourceQueueName: 'JC-BOT-SKILL2',
          sourceAgentFullName: '',
          sourceAgentLDAP: '',
          sourceContactId: '',
          targetContactId: ''
        },
        {
          timeStamp: '2019-10-10 10:52:56.407+0000',
          targetQueueName: 'CCT-Eng-Admin-Msg',
          sourceQueueName: 'JC-BOT-SKILL2',
          sourceAgentFullName: '',
          sourceAgentLDAP: '',
          targetAgentFullName: '',
          targetAgentLDAP: '',
          sourceContactId: '',
          targetContactId: ''
        }
      ],
      convInfoAfterItsClosed: {
        startTime: '2019-10-10 10:50:07.498+0000',
        endTime: '2019-10-10 10:54:18.515+0000',
        duration: 251017,
        contactId: 'unique initial contact Id',
        latestAgentLDAP: 'tec83385@adobe.com',
        latestQueueName: 'CCT-Eng-Admin-Msg',
        closeReason: 'AGENT / Customer/ system  : who closed Conv ?',
        latestQueueState: 'ACTIVE / INQUEUE : Before Closing conversation, Conv Assigned to agent ( Active ) or sitting in Queue ( INQueue)'
      },
      allowedAgent: false,
      transferInProgressId: '',
      caseNotesEligible: false,
    },
    ocAppData: {
      mockOfferIdFlag: false,
      darkMode,
      language: globalLanguage,
      modifiedCustomer: {
        rengaId: ccRengId,
        email: ccEmail,
        firstName: ccFirstName,
        lastName: ccLastName,
        contactId: ccContactId,
        phone: ccPhone
      },
      closedConversation,
      closedPhoneCall,
      originForCurrentConversation: origin || originForCurrentConversation,
      env_secrets: {
        REACT_APP_PORT: config.REACT_APP_PORT,
        REACT_APP_CCM_BASE_URL: config.REACT_APP_CCM_BASE_URL,
        REACT_APP_ECM_BASE_URL: config.REACT_APP_ECM_BASE_URL,
        REACT_APP_CIP_BASE_URL: config.CIP_DOMAIN,
        REACT_APP_OAC_BASE_URL: '',
        REACT_APP_OKTA_CLIENT_ID: config.REACT_APP_OKTA_CLIENT_ID,
        REACT_APP_OKTA_ISSUER_ID: config.REACT_APP_OKTA_ISSUER_ID,
        REACT_APP_OKTA_TESTING: config.REACT_APP_OKTA_TESTING,
        REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: config.REACT_APP_ENT_CUSTOMER_DEEPLINK_URL,
        REACT_APP_ENT_CASE_DEEPLINK_URL: config.REACT_APP_ENT_CASE_DEEPLINK_URL,
        REACT_APP_CUSTOMER_DEEPLINK_URL: config.REACT_APP_CUSTOMER_DEEPLINK_URL,
        REACT_APP_CASE_DEEPLINK_URL: config.REACT_APP_CASE_DEEPLINK_URL,
        REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: config.REACT_APP_ADD_CUSTOMER_DEEPLINK_URL,
        REACT_APP_CCM_UCI_DYNAMIC_URL: config.REACT_APP_CCM_UCI_DYNAMIC_URL,
        REACT_APP_HENDRIX_DOMAIN: config.HENDRIX_DOMAIN,
        AGENT_CONSOLE_SERVICE_BASE_URL: config.CCP_API_END_POINT,
        REACT_APP_ECM_SEARCH_CUSTOMER_URL :config.REACT_APP_ECM_SEARCH_CUSTOMER_URL
      },
      updatedToMilo
    }
  }

  console.log('Data from custom hook for widgets', json)
  return json
}

export default useContextData
